import { css } from 'styled-components';

interface TextInputProps {
  contentWidth?: string;
  contentHeight?: string;
  isError?: boolean;
}

export default css<TextInputProps>`
  width: ${({ contentWidth }) => contentWidth ?? '100%'};
  height: ${({ contentHeight }) => contentHeight ?? '44px'};
  padding: 10px 16px;
  color: ${({ theme }) => theme.color.gray[900]};
  ${({ theme }) => theme.font.body3};
  border: 1px solid ${({ theme, isError }) => isError ? theme.color.red[300] : theme.color.gray[300]};
  border-radius: 2px;
  background-color: ${({ theme }) => theme.color.white};
  transition: all 150ms ease-out;

  &:focus {
    border-color: ${({ theme }) => theme.color.gray[900]};
  }

  &:disabled {
    color: ${({ theme }) => theme.color.gray[500]};
    border-color: ${({ theme }) => theme.color.gray[300]};
    background-color: ${({ theme }) => theme.color.gray[50]};

    &::placeholder {
      color: ${({ theme }) => theme.color.gray[500]};
    }
  }
`;
