import React from 'react';

import { useSearchHistoryStore } from '~/store/search-history';

import { ReactComponent as CloseSvg } from '../images/ic_24_close_gray700.svg';
import {
  RecentBtn, Name, TagWrap, NumTag, TextTag,
  RemoveBtn,
} from '../styled';

interface Props {
  item: Search.SearchItem;
  onClick: (item: Search.SearchItem) => Promise<void>;
}

function SubwayRecentBtnComponent({
  item,
  onClick,
}: Props) {
  const removeSingleHistory = useSearchHistoryStore(store => store.removeSingleHistory);

  return (
    <RecentBtn>
      <div onClick={onClick.bind(null, item)}>
        <TagWrap>
          {item.child?.map(c => {
            if (!isNaN(+c.shortName) && Number.isInteger(+c.shortName)) {
              return (
                <NumTag key={c.name} $color={c.color}>
                  {c.shortName}
                </NumTag>
              );
            }

            return (
              <TextTag key={c.name} $color={c.color}>
                {c.shortName}
              </TextTag>
            );
          })}
        </TagWrap>

        <Name>{item.fullName}</Name>
      </div>

      <RemoveBtn onClick={removeSingleHistory.bind(null, item.id)}>
        <CloseSvg />
      </RemoveBtn>
    </RecentBtn>
  );
}

export default SubwayRecentBtnComponent;
