import React from 'react';
import { forwardRef, ButtonHTMLAttributes, FunctionComponent, SVGProps } from 'react';
import styled from 'styled-components';
import btnStd from '~/components/common-styled/button';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  btnStyle: 'solid' | 'line' | 'container' | 'text';
  color: 'gray' | 'red' | 'blue';
  size: 'xl' | 'lg' | 'md' | 'sm';
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  iconSize?: string;
  boxPosition?: 'right' | 'left' | 'none';
}

export const Button = styled.button`
  ${btnStd};
  flex: none;
`;

const ButtonComponent = forwardRef<HTMLButtonElement, Props>(({
  btnStyle, color, size, Icon, iconSize, boxPosition,
  children, ...props
}, ref) => {
  const hasIcon = Icon ? true :  false;

  return (
    <>
      {boxPosition === 'none' && (
        <Button
          ref={ref}
          btnStyle={btnStyle}
          color={color}
          size={size}
          hasIcon={false}
          {...props}
        >
          <span>{children}</span>
        </Button>
      )}  

      {boxPosition === 'left' && (
        <Button
          ref={ref}
          btnStyle={btnStyle}
          color={color}
          size={size}
          hasIcon={hasIcon}
          boxPosition={boxPosition}
          {...props}
        >
          {Icon && <Icon width={iconSize ?? '24px'} height={iconSize ?? '24px'} />}
          <span>{children}</span>
        </Button>
      )}

      {boxPosition === 'right' && (
        <Button
          ref={ref}
          btnStyle={btnStyle}
          color={color}
          size={size}
          hasIcon={hasIcon}
          boxPosition={boxPosition}
          {...props}
        >
          <span>{children}</span>
          {Icon && <Icon width={iconSize ?? '24px'} height={iconSize ?? '24px'} />}
        </Button>
      )}
    </>
  );
});

ButtonComponent.defaultProps = {
  boxPosition: 'none',
};

export default ButtonComponent;
