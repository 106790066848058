import { css } from 'styled-components';

import off from '~/components/svg-icon/images/ic-24-checkbox-enabled.svg';
import on from '~/components/svg-icon/images/ic-24-checkbox-checked.svg';
import disabled from '~/components/svg-icon/images/ic-24-checkbox-enabled.svg';
import onWithDisabled from '~/components/svg-icon/images/ic-24-checkbox-disabled.svg';

export default css`
  width: 24px;
  height: 24px;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: url(${off}) center center no-repeat;
  background-size: cover;
  appearance: none;
  cursor: pointer;

  &:checked {
    background-image: url(${on});
  }

  &:disabled {
    background-image: url(${disabled});
  }

  &:checked:disabled {
    background-image: url(${onWithDisabled});
  }
`;
