import { useState, useReducer, useEffect, useMemo, useCallback, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { produce } from 'immer';
import Cookie from 'js-cookie';

import { RootState } from '~/store';

import isEmpty from '~/utils/valid/is-empty';
import checkName from '~/utils/valid/check-name';
import checkBirth from '~/utils/valid/check-birth';
import checkTel from '~/utils/valid/check-tel';
import getErrorMessage from '~/utils/get-error-message';

import useTimer from '~/hooks/useTimer';
import useApiCall from '~/hooks/useApiCall';

import { toast } from '~/components/toast';

interface State {
  name: string;
  birth: string;
  genderNum: string;
  isCheckAll: boolean;
  isCheckRequire: boolean;
  checkTerms: number[];
  mobileCo: string;
  phone: string;
  authNo: string;
  errorName: any;
  errorBirth: any;
  errorGenderNum: any;
  errorPhone: any;
  errorAuthNo: string;
  isLoading: boolean;
  step1Disabled: boolean;
  step2Disabled: boolean;
}

interface Props {
  // callback: ({ name, phone }: { name: string; phone: string; }) => Promise<void>;
  callback: any;
}

type TTerm = {
  agree: any;
  required: boolean;
  termsDate: any;
  termsHistorySeq: any;
  termsName: string;
  termsSeq: any;
  termsShortName: any;
  url: string;
  checked: boolean;
}

export type TCertERror = {
  message: string;
  email?: string;
  [propName: string]: any;
}

const reducer = (state: State, action: Partial<State>) => ({
  ...state,
  ...action
});

function useCertification({ callback }: Props) {
  const [terms, setTerms] = useState<TTerm[]>([]);
  const [certError, setCertError] = useState<TCertERror | null>(null);
  const [requestSeq, setRequestSeq] = useState<string>('');
  const [isSending, setSending] = useState(false); // api loading
  const [disableSendBtn, setDisableSendBtn] = useState(false); // 전송버튼
  const [disableConfirmBtn, setDisableConfirmBtn] = useState(true); // 확인버튼
  const [isCompleted, setCompleted] = useState(false); // 인증확인까지 완료

  const initState: State = useMemo(() => ({
    name: '',
    birth: '',
    genderNum: '',
    isCheckAll: false,
    isCheckRequire: false,
    checkTerms: [],
    mobileCo: '',
    phone: '',
    authNo: '',
    errorName: '',
    errorBirth: '',
    errorGenderNum: '',
    errorPhone: '',
    errorAuthNo: '',
    isLoading: false,
    step1Disabled: false,
    step2Disabled: true,
  }), []);
  const [state, setState] = useReducer(reducer, initState);

  const user = useSelector(({ user }: RootState) => user);

  const [count, progress, start, stop] = useTimer(180);
  const { post, get } = useApiCall();

  useEffect(() => {
    getTerms();
    Cookie.remove('safeAuthConfirm');

    return () => {
      reset();
      Cookie.remove('safeAuthConfirm');
    };
  }, []);

  useEffect(() => {
    checkSendBtn({ ...state });
  }, [state.name, state.birth, state.genderNum, state.isCheckAll, state.isCheckRequire, state.mobileCo, state.phone, state.errorName, state.errorBirth, state.errorGenderNum, state.errorPhone]);

  function reset() {
    setTerms([]);
    setCertError(null);
    setRequestSeq('');
    setDisableSendBtn(false);
    setDisableConfirmBtn(true);
    setCompleted(false);
    setState(initState);
  }

  async function getTerms() {
    // 로그인 상태가 아니거나 본인인증하지 않은 경우 선택약관을 포함한 약관을 호출
    const url = user.isAuth && user.isSafeAuth ? '/api/3/terms/safe-auth/edit' : '/api/3/terms/safe-auth/add';

    try {
      const terms: TTerm[] = await get(url);
      const tempTerms = terms.map(term => {
        return {
          ...term,
          checked: false
        };
      });

      setTerms(tempTerms);
    } catch(err) {
      const msg = getErrorMessage(err).msg;
      toast.error(msg);
    }
  }

  function changeInput(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const { name, value } = e.currentTarget;

    if (name === 'name' && !value.match(/^[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z|A-Z|\s]{0,40}$/)) return;
    if (['birth', 'authNo'].includes(name) && !/^[0-9]{0,6}$/.test(value)) return;
    if (name === 'genderNum' && !/^[0|1|2|3|4|5|6|7|8|9]{0,1}$/.test(value)) return;
    if (name === 'phone' && !/^[0-9]{0,11}$/.test(value)) return;

    if (name === 'birth') {
      let res = checkBirth(value, state.genderNum);
      setState({ [name]: value, ...res });

    } else if(name === 'genderNum') {
      let res = checkBirth(state.birth, value);
      setState({ [name]: value, ...res });

    } else {
      setState({ [name]: value });
      debounceFn(e);
    }
  }

  const debounceFn = useCallback(debounce(validInput, 100), []);
  function validInput(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    let res = null;
    const { name, value } = e.target;

    switch(name) {
      case 'name':
        res = checkName(value);
        setState({ errorName: res ? res : '' });
        break;

      case 'phone':
        res = checkTel(value);
        setState({ errorPhone: res ? res : '' });
        break;

      default:
        break;
    }
  }

  function checkAllTerm() {
    const nextFlag = !state.isCheckAll;

    setState({ isCheckAll: nextFlag, isCheckRequire: nextFlag });
    setTerms(prev => prev.map(term => {
      return {
        ...term,
        checked: nextFlag,
      };
    }));
  }

  function checkTerm(e: ChangeEvent<HTMLInputElement>) {
    const { id, checked } = e.currentTarget;
    const nextTerms = produce(terms, draft => { draft[+id].checked = checked; });
    const isCheckAll = terms.length === nextTerms.filter(i => i.checked).length;
    const isCheckRequire = nextTerms.filter(term => term.required && !term.checked).length === 0;

    setState({ isCheckAll, isCheckRequire });
    setTerms(nextTerms);
  }

  const checkSendBtn = useCallback(debounce(validBtn, 500), []);
  function validBtn(obj: Partial<State>) {
    const { name, birth, genderNum, isCheckAll, isCheckRequire, mobileCo, phone, errorName, errorBirth, errorGenderNum, errorPhone } = obj;
    const isNotEmptyVal = [name, birth, genderNum, isCheckAll, isCheckRequire, phone, mobileCo].every(val => !isEmpty(val));
    const isEmptyError = [errorName, errorBirth, errorGenderNum, errorPhone].every(val => isEmpty(val));

    setDisableSendBtn(!(isNotEmptyVal && isEmptyError));
  }

  /**
   * TODO: 제거예정
   * @description 회원가입 인증번호 전송 함수
   */
  async function step1NonAuth() {
    if (isSending) return;

    const { birth, genderNum, mobileCo, phone, name } = state;

    setSending(true);

    try {
      const res = await post(`/api/3/safe-auth/step1`, {
        jumin: birth + genderNum,
        mobile_co: mobileCo,
        mobile_no: phone,
        name,
      });

      if (res?.result) {
        setRequestSeq(res?.requestSeq ?? '');
        setState({ step1Disabled: true, authNo: '', errorAuthNo: '' });
        setDisableSendBtn(false);
        setDisableConfirmBtn(false);

        start(() => {
          setState({ authNo: '', errorAuthNo: '인증번호 입력 시간이 초과되었습니다. 재요청해주세요.' });
          setDisableSendBtn(false);
          setDisableConfirmBtn(true);
        });
      } else {
        toast.error(res?.message ?? '서버에러가 발생했습니다.');
      }

    } catch(err) {
      const msg = getErrorMessage(err).msg;
      toast.error(msg);
    }

    setSending(false);
  }

  /**
   * @description 회원가입 인증번호 확인 함수
   */
  async function step2NonAuth() {
    const { authNo } = state;

    if (authNo.length < 6) return setState({ errorAuthNo: '인증번호 6자리를 입력해주세요.' });
    if (isSending) return;

    setSending(true);

    const checkedTerms = terms.filter(term => term.checked && term.termsHistorySeq !== null);
    const termsSeqArr = checkedTerms?.map(term => term.termsHistorySeq) ?? [];

    try {
      const res = await post(`/api/3/safe-auth/step2`, {
        request_seq: requestSeq,
        auth_no: authNo,
        safe_auth_terms_history_seqs: JSON.stringify(termsSeqArr),
      });

      if (res?.result) {
        setRequestSeq('');
        setState({ step2Disabled: true, errorAuthNo: '' });
        setDisableSendBtn(true);
        setDisableConfirmBtn(true);
        setCompleted(true);
        stop();
        Cookie.set('safeAuthConfirm', res?.safeAuthConfirm ?? '');

      } else {
        setState({ errorAuthNo: res?.message ?? '서버에러가 발생했습니다.' });
      }

    } catch(err) {
      const msg = getErrorMessage(err).msg;
      toast.error(msg);
    }

    setSending(false);
  }

  /**
   * @description 회원가입 전 CI 확인
   */
  async function step3NonAuth() {
    if (isSending) return;

    setSending(true);

    try {
      const res = await get(`/api/3/safe-auth/join/step3`);

      if (res?.result) {
        const { name, phone } = state;
        callback && callback({ name, phone });
      } else {
        setCertError(res);
      }
    } catch(err) {
      const msg = getErrorMessage(err).msg;
      toast.error(msg);
    }

    setSending(false);
  }

  /**
   * @description 정보수정 인증번호 전송 함수
   */
  async function step1Auth() {
    if (isSending) return;

    const { birth, genderNum, mobileCo, phone, name } = state;

    setSending(true);

    try {
      const res = await post(`/api/3/safe-auth/check/step1`, {
        jumin: birth + genderNum,
        mobile_co: mobileCo,
        mobile_no: phone,
        name,
      });

      if (res?.result) {
        setRequestSeq(res?.requestSeq ?? '');
        setState({ step1Disabled: true, authNo: '', errorAuthNo: '' });
        setDisableSendBtn(false);
        setDisableConfirmBtn(false);

        start(() => {
          setState({ authNo: '', errorAuthNo: '인증번호 입력 시간이 초과되었습니다. 재요청해주세요.' });
          setDisableSendBtn(false);
          setDisableConfirmBtn(true);
        });

      } else {
        toast.error(res?.message ?? '서버에러가 발생했습니다.');
      }

    } catch(err) {
      const msg = getErrorMessage(err).msg;
      toast.error(msg);
    }

    setSending(false);
  }

  /**
   * @description 정보수정 인증번호 확인 함수
   */
  async function step2Auth() {
    const { authNo } = state;

    if (authNo.length < 6) return setState({ errorAuthNo: '인증번호 6자리를 입력해주세요.' });
    if (isSending) return;

    setSending(true);

    const checkedTerms = terms.filter(term => term.checked && term.termsHistorySeq !== null);
    const termsSeqArr = checkedTerms?.map(term => term.termsHistorySeq) ?? [];

    try {
      const res = await post('/api/3/safe-auth/check/step2', {
        request_seq: requestSeq,
        auth_no: authNo,
        safe_auth_terms_history_seqs: JSON.stringify(termsSeqArr),
      });

      if (res?.result) {
        setRequestSeq('');
        setState({ step2Disabled: true, errorAuthNo: '' });
        setDisableSendBtn(true);
        setDisableConfirmBtn(true);
        setCompleted(true);
        stop();
        Cookie.set('safeAuthConfirm', res?.safeAuthConfirm ?? '');

      } else {
        setState({ errorAuthNo: res?.message ?? '서버에러가 발생했습니다.' });
      }

    } catch(err) {
      const msg = getErrorMessage(err).msg;
      toast.error(msg);
    }

    setSending(false);
  }

  /**
   * @description 정보수정 전 CI 확인
   */
  async function step3Auth() {
    if (isSending) return;

    setSending(true);

    try {
      const res = await get(`/api/3/safe-auth/verify/step3`);

      if (res?.result) {
        const { name, phone } = state;
        callback && callback({ name, phone });

      } else {
        setCertError(res);
      }

    } catch(err) {
      const msg = getErrorMessage(err).msg;
      toast.error(msg);
    }

    setSending(false);
  }

  return {
    state,
    terms,
    isSending,
    disableSendBtn,
    disableConfirmBtn,
    isCompleted,
    certError,
    progress,
    count,
    changeInput,
    checkTerm,
    checkAllTerm,
    step1NonAuth,
    step2NonAuth,
    step3NonAuth,
    step1Auth,
    step2Auth,
    step3Auth,
  };
}

export default useCertification;
