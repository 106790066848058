import { css } from 'styled-components';

export default css`
  height: 44px;
  padding: 0 16px;
  color: ${({ theme }) => theme.form.getColor()};
  font-size: 14px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  background-color: #fff;

  &:focus {
    border-color: ${({ theme }) => theme.blue.getColor()};
  }

  &:disabled {
    color: ${({ theme }) => theme.inactive.getColor()};
    border-color: ${({ theme }) => theme.line02.getColor()};
    background-color: ${({ theme }) => theme.bgGray.getColor()};
  }

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.inactive.getColor()};
  }
  &:-ms-input-placeholder {
    color: ${({ theme }) => theme.inactive.getColor()};
  }
  &::placeholder {
    color: ${({ theme }) => theme.inactive.getColor()};
  }
`;
