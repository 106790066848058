import React, { forwardRef, InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import Input from '~/components/design-guide/input';

interface WrapProps {
  spaceTop: string;
  spaceBottom: string;
}

const InputWrap = styled.div<WrapProps>`
  display: inline-flex;
  flex-direction: column;
  margin-top: ${({ spaceTop }) => spaceTop};
  margin-bottom: ${({ spaceBottom }) => spaceBottom};
`;

const Label = styled.label`
  display: inline-flex;
  flex-direction: column;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;

  > p.label-text {
    margin-bottom: 8px;
    font-weight: 700;
    ${({ theme }) => theme.font.body3};

    > span {
      margin-left: 4px;
      color: ${({ theme }) => theme.color.blue[500]};
    }
  }
`;

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  width?: string;
  height?: string;
  labelText?: string;
  isRequire?: boolean;
  showError?: boolean;
  errorText?: string;
  spaceTop: string;
  spaceBottom: string;
}

const InputWithLabelComponent = forwardRef<HTMLInputElement, Props>(({
  spaceTop, spaceBottom,
  labelText, isRequire,
  width, height,
  ...props
}, ref) => {
  return (
    <InputWrap
      spaceTop={spaceTop}
      spaceBottom={spaceBottom}
    >
      <Label>
        {labelText && (
          <p className="label-text">
            {labelText}
            {isRequire && <span>*</span>}
          </p>
        )}
      
        <Input
          ref={ref}
          width={width ?? '100%'}
          height={height ?? '44px'}
          {...props}
        />
      </Label>
    </InputWrap>
  );
});

InputWithLabelComponent.defaultProps = {
  spaceTop: '0',
  spaceBottom: '0',
  labelText: '',
  isRequire: false,
  errorText: '',
  showError: true,
};

export default InputWithLabelComponent;
