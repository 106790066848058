import React from 'react';

import mobileCarrier from '~/enum/mobile-carrier';

import isEmpty from '~/utils/valid/is-empty';

import useCertification from '~/hooks/useCertification';

import InputWithLabel from '~/components/design-guide/input-with-label';
import InputWithUnit from '~/components/design-guide/input-with-unit';
import Input from '~/components/design-guide/input';
import LabelCheckbox from '~/components/design-guide/label-checkbox';
import Select from '~/components/design-guide/select';
import SvgIcon from '~/components/svg-icon';
import Button from '~/components/design-guide/button';

import Scrollbar from 'web~/components/scrollbar';
import Modal from 'web~/components/modal';

import { Wrap, CertForm, Label, JuminBox, TermsBox, TermsWrap, InputBox } from './styled';

interface Props {
  // callback: ({ name, phone }: { name: string; phone: string; }) => Promise<void>;
  callback: any;
  handler: () => void;
}

function toMMSS(second: number = 0) {
  let sec_num = parseInt(second + '', 10); // don't forget the second param
  let minutes: number | string = Math.floor((sec_num) / 60);
  let seconds: number | string = sec_num - (minutes * 60);

  if (minutes < 10) minutes = `0${minutes}`;
  if (seconds < 10) seconds = `0${seconds}`;

  return `${minutes}:${seconds}`;
}

function CertPopup({ callback, handler }: Props) {
  const {
    state: {
      name, birth, genderNum, isCheckRequire, isCheckAll, mobileCo, phone, authNo,
      errorName, errorBirth, errorGenderNum, errorPhone, errorAuthNo,
      step1Disabled,
    },
    terms,
    certError,
    disableSendBtn,
    disableConfirmBtn,
    isCompleted,
    count,
    changeInput,
    checkTerm,
    checkAllTerm,
    step1Auth,
    step2Auth,
    step3Auth,
  } = useCertification({ callback });

  const isError = !isEmpty(certError);

  return (
    <Modal
      type="modal"
      minWidth={524}
      handle={() => {}}
    >
      <Wrap height={isError ? '' : '800px'}>
        <div className="header">
          <h1>본인인증</h1>
        </div>

        {isError ? (
          <div className="error-content">
            <p className="normal">{certError?.message}</p>
            {certError?.email && <p className="email">{certError.email}</p>}

            <div className="btn-wrap">
              <Button
                btnStyle="line"
                color="gray"
                size="xl"
                onClick={handler.bind(null)}
              >
                닫기
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div className="content">
              <Scrollbar>
                <CertForm>
                  <h2>본인인증을 위해 아래 정보를 입력해주세요.</h2>

                  <InputWithLabel
                    type="text"
                    name="name"
                    value={name}
                    onChange={changeInput.bind(null)}
                    spaceTop="0"
                    spaceBottom="24px"
                    labelText="이름"
                    width="460px"
                    placeholder="이름 입력"
                    disabled={step1Disabled}
                    errorText={errorName}
                  />

                  <Label>주민등록번호</Label>
                  <JuminBox>
                    <Input
                      type="text"
                      name="birth"
                      value={birth}
                      onChange={changeInput.bind(null)}
                      width="218px"
                      placeholder="주민번호 앞 6자리"
                      disabled={step1Disabled}
                      showError={false}
                      errorText={errorBirth}
                    />

                    <span />

                    <Input
                      type="text"
                      name="genderNum"
                      value={genderNum}
                      onChange={changeInput.bind(null)}
                      width="44px"
                      disabled={step1Disabled}
                      showError={false}
                      errorText={errorGenderNum}
                    />

                    <p className="body3 bold">●●●●●●</p>
                  </JuminBox>

                  {(errorBirth || errorGenderNum) && <p className="error-text">{errorBirth || errorGenderNum}</p>}

                  <TermsBox>
                    <LabelCheckbox
                      checked={isCheckAll}
                      disabled={step1Disabled}
                      onChange={checkAllTerm.bind(null)}
                    >
                      <p className="body3 bold">모두 동의합니다.</p>
                    </LabelCheckbox>

                    <TermsWrap>
                      {terms?.length > 0 && terms.map((term, idx) => {
                        return (
                          <div className="term" key={idx}>
                            <LabelCheckbox
                              id={String(idx)}
                              value={term?.termsHistorySeq ?? ''}
                              checked={term.checked}
                              disabled={step1Disabled}
                              onChange={checkTerm.bind(null)}
                            >
                              <p className="body3">
                                [{term.required ? '필수' : '선택'}] {term.termsName}
                              </p>
                            </LabelCheckbox>
                            {term.url && <SvgIcon name="ic-24-arrow-right-gray-700" onClick={() => window.open(term.url, '_blank')} />}
                          </div>
                        );
                      })}
                    </TermsWrap>
                  </TermsBox>

                  {isCheckRequire && (
                    <>
                      <Select
                        name="mobileCo"
                        value={mobileCo}
                        width="460px"
                        spaceTop="40px"
                        spaceBottom="24px"
                        labelText="통신사"
                        onChange={changeInput}
                        disabled={step1Disabled}
                      >
                        <option value="">통신사 선택</option>
                        {mobileCarrier.map(item => <option key={item.type} value={item.type}>{item.label}</option>)}
                      </Select>

                      <Label>휴대폰번호</Label>
                      <InputBox>
                        <div>
                          <Input
                            type="text"
                            name="phone"
                            value={phone}
                            onChange={changeInput.bind(null)}
                            width="340px"
                            placeholder="-없이 숫자만 입력"
                            disabled={step1Disabled}
                            showError={false}
                            errorText={errorPhone}
                          />

                          <Button
                            btnStyle="solid"
                            color="gray"
                            size="lg"
                            onClick={step1Auth.bind(null)}
                            disabled={disableSendBtn}
                          >
                            {step1Disabled ? '재전송' : '인증번호 전송'}
                          </Button>
                        </div>
                        {errorPhone && <p className="error-text">{errorPhone}</p>}
                      </InputBox>

                      <Label>인증번호</Label>
                      <InputBox>
                        <div>
                          <InputWithUnit
                            type="text"
                            name="authNo"
                            value={authNo}
                            onChange={changeInput.bind(null)}
                            width="340px"
                            placeholder="인증번호 입력"
                            disabled={disableConfirmBtn}
                            unit={step1Disabled ? <p className="unit">{toMMSS(count)}</p> : null}
                            showError={false}
                            errorText={errorAuthNo}
                          />

                          <Button
                            btnStyle="solid"
                            color="gray"
                            size="lg"
                            disabled={disableConfirmBtn}
                            onClick={step2Auth.bind(null)}
                          >
                            인증번호 확인
                          </Button>
                        </div>
                        {errorAuthNo && <p className="error-text">{errorAuthNo}</p>}
                      </InputBox>
                    </>
                  )}
                </CertForm>
              </Scrollbar>
            </div>

            <div className="footer">
              <Button
                btnStyle="line"
                color="gray"
                size="xl"
                onClick={handler.bind(null)}
              >
                닫기
              </Button>

              <Button
                btnStyle="solid"
                color="blue"
                size="xl"
                disabled={!isCompleted}
                onClick={step3Auth.bind(null)}
              >
                본인인증 완료
              </Button>
            </div>
          </>
        )}
      </Wrap>
    </Modal>
  );
}

export default CertPopup;
