import sm from './sm';
import md from './md';
import lg from './lg';
import xl from './xl';

const btnSize = {
  sm,
  md,
  lg,
  xl,
};

export default btnSize;
