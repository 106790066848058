import styled, { keyframes } from 'styled-components';
import { layout } from '~/components/styled/layout';

export const Wrap = styled.div`
  ${layout};
  display: flex;
  align-items: center;
  height: 100%;
  
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const ListWrap = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const SmallWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const PageWrap = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const SvgWrap = styled.div`
  width: 40px;
  height: 40px;
  margin: 0 auto;
  margin-bottom: 20px;
  position: relative;
`;

const movedash = keyframes`
	to {
    stroke-dashoffset: 250%;
  }
`;

export const Svg = styled.svg`
  polygon {
    fill: none;
    stroke-width: 8px;
    stroke: #326cf9;
    stroke-dasharray: 40;
    stroke-dashoffset: 50%;
    animation: ${movedash} 1.5s forwards 0s infinite;
  }
`;

export const Logo = styled.svg`
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Text = styled.p`
  margin-top: 2px;
  color: #444444;
  font-size: 14px;
  line-height: 20px;
`;

export const BarWrap = styled.div`
  flex: none;
  width: 100%;
  height: 0;
  position: relative;
  z-index: 2;
`;

const wave = keyframes`
  0% {
    left: -10%;
    right: 110%;
  }
  38% {
    left: -10%;
    right: -10%;
  }
  62% {
    left: -10%;
    right: -10%;
  }
  100% {
    left: 110%;
    right: -10%;
  }
`;

export const Bar = styled.div`
  height: 3px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.color.blue[500]};
  animation: ${wave} 1.6s infinite ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;
