// 모바일 에이전트 구분
export default {
  Android() {
    return navigator.userAgent.match(/Android/i) !== null;
  },
  BlackBerry() {
    return navigator.userAgent.match(/BlackBerry/i) !== null;
  },
  IOS() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null;
  },
  Opera() {
    return navigator.userAgent.match(/Opera Mini/i) !== null;
  },
  Windows() {
    return navigator.userAgent.match(/IEMobile/i) !== null;
  },
  any() {
    return (this.Android() || this.BlackBerry() || this.IOS() || this.Opera() || this.Windows());
  }
};
