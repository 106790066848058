import React, { useRef, useEffect } from 'react';

import { TModal } from '~/@types/modal';

import { BtnWrap, CancelBtn, SubmitBtn } from './styled';

interface Props {
  cancelHandle: GetIndexType<TModal, 'cancelHandle'>;
  handle: GetIndexType<TModal, 'handle'>;
  cancelName: GetIndexType<TModal, 'cancelName'>;
  submitName: GetIndexType<TModal, 'submitName'>;
  children: GetIndexType<TModal, 'children'>;
};

function ConfirmComponent({
  cancelHandle, handle, cancelName, submitName, children
}: Props) {
  const _submitBtn = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    _submitBtn.current?.focus();
  }, []);

  return (
    <>
      {children}
      <BtnWrap>
        <CancelBtn onClick={cancelHandle}>{cancelName}</CancelBtn>
        <SubmitBtn ref={_submitBtn} onClick={handle}>{submitName}</SubmitBtn>
      </BtnWrap>
    </>
  );
}

export default ConfirmComponent;
