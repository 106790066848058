import dutyList from '~/enum/duty';
import isEmpty from '~/utils/valid/is-empty';

function dutyFormat(duty: string = '', hasBracket: boolean = true): string {
  if (isEmpty(duty)) return '';
  
  const userDuty = dutyList.find(d => d.value.toString() === duty.toString());

  if (hasBracket) return `(${userDuty?.label ?? ''})`;
  return userDuty?.label ?? '';
}

export default dutyFormat;