import { BtnStdProps } from '.';

import blueBtnColor from './color/blue';
import redBtnColor from './color/red';
import grayBtnColor from './color/gray';

function getColor(btnStyle: GetIndexType<BtnStdProps, 'btnStyle'>, color: GetIndexType<BtnStdProps, 'color'>) {
  if (btnStyle === 'line') {
    switch (color) {
      case 'gray':
        return grayBtnColor.line;

      case 'red':
        return redBtnColor.line;

      case 'blue':
        return blueBtnColor.line;

      default:
        break;
    }

  } else if (btnStyle === 'solid') {
    switch (color) {
      case 'gray':
        return grayBtnColor.fill;

      case 'red':
        return redBtnColor.fill;

      case 'blue':
        return blueBtnColor.fill;
    }

  } else if (btnStyle === 'container' || btnStyle === 'text') {
    switch (color) {
      case 'gray':
        return grayBtnColor.container;

      case 'red':
        return redBtnColor.container;

      case 'blue':
        return blueBtnColor.container;
    }
  }
}

export default getColor;
