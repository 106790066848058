import styled from 'styled-components';
import { lineBtnStd } from '~/atoms2';

export const SearchResultContent = styled.div`
  height: 100%;
  padding: 32px 32px 40px;

  > h1 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font.body2};
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Seq = styled.p`
  margin-right: 12px;
  color: ${({ theme }) => theme.color.blue[500]};
  ${({ theme }) => theme.font.body2};
`;

export const LineLink = styled.a`
  ${lineBtnStd};
`;
