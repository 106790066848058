import React, { useRef, useEffect } from 'react';

import { TModal } from '~/@types/modal';

import { BtnWrap, SubmitBtn } from './styled';

interface Props {
  handle: GetIndexType<TModal, 'handle'>;
  submitName: GetIndexType<TModal, 'submitName'>;
  children: GetIndexType<TModal, 'children'>;
};

function AlertComponent({ handle, submitName, children }: Props) {
  const _submitBtn = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    _submitBtn.current?.focus();
  }, []);

  return (
    <>
      {children}
      <BtnWrap>
        <SubmitBtn ref={_submitBtn} onClick={handle}>{submitName}</SubmitBtn>
      </BtnWrap>
    </>
  );
}

export default AlertComponent;
