import { useRef, useLayoutEffect, useEffect, useCallback } from 'react';

import { TModal } from '~/@types/modal';

type ModalProps = Pick<TModal, 'type' | 'id' | 'handle' | 'cancelHandle' | 'escToClose'>;

function useModal({ type, id, handle, cancelHandle, escToClose }: Partial<ModalProps>) {
  const _elem = useRef(document.createElement('div'));
  _elem.current.id = id ?? 'modal';

  useLayoutEffect(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const root = document.getElementById('root') as HTMLDivElement;

    root.style.position = 'fixed';
    root.style.top = `-${scrollTop}px`;
    root.style.left = `-${scrollLeft}px`;

    return () => {
      const scrollTop = root.style.top;
      const scrollLeft = root.style.left;
      root.style.position = '';
      root.style.top = '';
      window.scrollTo(parseInt(scrollLeft || '0', 10) * -1, parseInt(scrollTop || '0', 10) * -1);
    };
  }, []);

  const onKeyDown = useCallback(e => {
    if (e.code === 'Escape') {
      if (type === 'confirm') {
        cancelHandle && cancelHandle(e);

      } else {
        handle && handle(e);
      }

      document.removeEventListener('keydown', onKeyDown);
    }
  }, [handle, escToClose]);

  useEffect(() => {
    if (escToClose) {
      document.addEventListener('keydown', onKeyDown, false);
    }
    
    document.body.appendChild(_elem.current);

    return () => {
      if (escToClose) {
        document.removeEventListener('keydown', onKeyDown);
      }

      // 지우려는 모달이 존재하는지 우선 체크
      if (document.body.contains(_elem.current)) {
        document.body.removeChild(_elem.current);
      }
    };
  }, []);

  return _elem;
}

export default useModal;
