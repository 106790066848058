import { css } from 'styled-components';

export const headline1White = css`
  color: ${({ theme }) => theme.white.getColor()};
  font-size: 40px;
  font-weight: 400;
`;

export const headline2Title = css`
  color: ${({ theme }) => theme.title.getColor()};
  font-size: 32px;
  font-weight: 400;
`;

export const headline3Title = css`
  color: ${({ theme }) => theme.title.getColor()};
  font-size: 28px;
  font-weight: 400;
`;

export const headline4WhiteB = css`
  color: ${({ theme }) => theme.white.getColor()};
  font-size: 24px;
  font-weight: 700;
`;

export const headline3TitleB = css`
  color: ${({ theme }) => theme.title.getColor()};
  font-size: 30px;
  font-weight: 700;
`;

export const headline4TitleB = css`
  color: ${({ theme }) => theme.title.getColor()};
  font-size: 24px;
  font-weight: 700;
`;

export const headline4BlueB = css`
  color: ${({ theme }) => theme.blue.getColor()};
  font-size: 24px;
  font-weight: 700;
`;

export const headline4White = css`
  color: ${({ theme }) => theme.white.getColor()};
  font-size: 24px;
  font-weight: 400;
`;

export const headline4Title = css`
  color: ${({ theme }) => theme.title.getColor()};
  font-size: 24px;
  font-weight: 400;
`;

export const subtitle1TitleB = css`
  color: ${({ theme }) => theme.title.getColor()};
  font-size: 18px;
  font-weight: 700;
`;

export const subtitle1WhiteB = css`
  color: ${({ theme }) => theme.white.getColor()};
  font-size: 18px;
  font-weight: 700;
`;

export const subtitle1Blue = css`
  color: ${({ theme }) => theme.blue.getColor()};
  font-size: 18px;
  font-weight: 400;
`;

export const subtitle1Title = css`
  color: ${({ theme }) => theme.title.getColor()};
  font-size: 18px;
  font-weight: 400;
`;

export const subtitle2BlueB = css`
  color: ${({ theme }) => theme.blue.getColor()};
  font-size: 16px;
  font-weight: 700;
`;

export const subtitle2TitleB = css`
  color: ${({ theme }) => theme.title.getColor()};
  font-size: 16px;
  font-weight: 700;
`;

export const subtitle2Inactive = css`
  color: ${({ theme }) => theme.inactive.getColor()};
  font-size: 16px;
  font-weight: 400;
`;

export const subtitle2White = css`
  color: ${({ theme }) => theme.white.getColor()};
  font-size: 16px;
  font-weight: 400;
`;

export const subtitle2Title = css`
  color: ${({ theme }) => theme.title.getColor()};
  font-size: 16px;
  font-weight: 400;
`;

export const body1WhiteB = css`
  color: ${({ theme }) => theme.white.getColor()};
  font-size: 15px;
  font-weight: 700;
`;

export const body1TitleB = css`
  color: ${({ theme }) => theme.title.getColor()};
  font-size: 15px;
  font-weight: 700;
`;

export const body1BlueB = css`
  color: ${({ theme }) => theme.blue.getColor()};
  font-size: 15px;
  font-weight: 700;
`;

export const body1Form = css`
  color: ${({ theme }) => theme.form.getColor()};
  font-size: 15px;
  font-weight: 400;
`;

export const body1Info = css`
  color: ${({ theme }) => theme.info.getColor()};
  font-size: 15px;
  font-weight: 400;
`;

export const body1Inactive = css`
  color: ${({ theme }) => theme.inactive.getColor()};
  font-size: 15px;
  font-weight: 400;
`;

export const body1Blue = css`
  color: ${({ theme }) => theme.blue.getColor()};
  font-size: 15px;
  font-weight: 400;
`;

export const body1White = css`
  color: ${({ theme }) => theme.white.getColor()};
  font-size: 15px;
  font-weight: 400;
`;

export const body1Contents = css`
  color: ${({ theme }) => theme.contents.getColor()};
  font-size: 15px;
  font-weight: 400;
`;

export const body1Title = css`
  color: ${({ theme }) => theme.title.getColor()};
  font-size: 15px;
  font-weight: 400;
`;

export const body2WhiteB = css`
  color: ${({ theme }) => theme.white.getColor()};
  font-size: 14px;
  font-weight: 700;
`;

export const body2TitleB = css`
  color: ${({ theme }) => theme.title.getColor()};
  font-size: 14px;
  font-weight: 700;
`;

export const body2Form = css`
  color: ${({ theme }) => theme.form.getColor()};
  font-size: 14px;
  font-weight: 400;
`;

export const body2White = css`
  color: ${({ theme }) => theme.white.getColor()};
  font-size: 14px;
  font-weight: 400;
`;

export const body2Inactive = css`
  color: ${({ theme }) => theme.inactive.getColor()};
  font-size: 14px;
  font-weight: 400;
`;

export const body2Contents = css`
  color: ${({ theme }) => theme.contents.getColor()};
  font-size: 14px;
  font-weight: 400;
`;

export const body2Title = css`
  color: ${({ theme }) => theme.title.getColor()};
  font-size: 14px;
  font-weight: 400;
`;

export const body2Info = css`
  color: ${({ theme }) => theme.info.getColor()};
  font-size: 14px;
  font-weight: 400;
`;

export const body2Blue = css`
  color: ${({ theme }) => theme.blue.getColor()};
  font-size: 14px;
  font-weight: 400;
`;

export const body2TitleL = css`
  color: ${({ theme }) => theme.title.getColor()};
  font-size: 14px;
  font-weight: 400;
`;

export const body3TitleB = css`
  color: ${({ theme }) => theme.title.getColor()};
  font-size: 13px;
  font-weight: 700;
`;

export const body3Info = css`
  color: ${({ theme }) => theme.info.getColor()};
  font-size: 13px;
  font-weight: 400;
`;

export const body3White = css`
  color: ${({ theme }) => theme.white.getColor()};
  font-size: 13px;
  font-weight: 400;
`;

export const body3Form = css`
  color: ${({ theme }) => theme.form.getColor()};
  font-size: 13px;
  font-weight: 400;
`;

export const body3Blue = css`
  color: ${({ theme }) => theme.blue.getColor()};
  font-size: 13px;
  font-weight: 400;
`;

export const body3Inactive = css`
  color: ${({ theme }) => theme.inactive.getColor()};
  font-size: 13px;
  font-weight: 400;
`;

export const body3BlueB = css`
  color: ${({ theme }) => theme.blue.getColor()};
  font-size: 13px;
  font-weight: 700;
`;

export const body3Contents = css`
  color: ${({ theme }) => theme.contents.getColor()};
  font-size: 13px;
  font-weight: 400;
`;

export const body3Title = css`
  color: ${({ theme }) => theme.title.getColor()};
  font-size: 13px;
  font-weight: 400;
`;

export const captionBlackB = css`
  color: ${({ theme }) => theme.title.getColor()};
  font-size: 12px;
  font-weight: 700;
`;

export const captionBlueB = css`
  color: ${({ theme }) => theme.blue.getColor()};
  font-size: 12px;
  font-weight: 700;
`;

export const captionWhiteB = css`
  color: ${({ theme }) => theme.white.getColor()};
  font-size: 12px;
  font-weight: 700;
`;

export const captionWhite = css`
  color: ${({ theme }) => theme.white.getColor()};
  font-size: 12px;
  font-weight: 400;
`;

export const captionBlue = css`
  color: ${({ theme }) => theme.blue.getColor()};
  font-size: 12px;
  font-weight: 400;
`;

export const captionBlack = css`
  color: ${({ theme }) => theme.title.getColor()};
  font-size: 12px;
  font-weight: 400;
`;

export const captionYellow = css`
  color: #ebb02e;
  font-size: 12px;
  font-weight: 400;
`;

export const captionInfo = css`
  color: ${({ theme }) => theme.info.getColor()};
  font-size: 12px;
  font-weight: 400;
`;

export const labelBlue02 = css`
  color: #166be5;
  font-size: 11px;
  font-weight: 400;
`;

export const labelRed02 = css`
  color: #e20724;
  font-size: 11px;
  font-weight: 400;
`;

export const labelNavy = css`
  color: ${({ theme }) => theme.navy.getColor()};
  font-size: 11px;
  font-weight: 400;
`;

export const labelWhite = css`
  color: ${({ theme }) => theme.white.getColor()};
  font-size: 11px;
  font-weight: 400;
`;

export const labelPurple = css`
  color: #5e6a8c;
  font-size: 11px;
  font-weight: 400;
`;

export const labelBlack = css`
  color: ${({ theme }) => theme.title.getColor()};
  font-size: 11px;
  font-weight: 400;
`;

export const labelRed = css`
  color: ${({ theme }) => theme.red.getColor()};
  font-size: 11px;
  font-weight: 400;
`;

export const labelGreen = css`
  color: #069378;
  font-size: 11px;
  font-weight: 400;
`;

export const truncate = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// New Style Guide
// 스타일 가이드 이름이 Body/body3-14-bold 경우 변수명은 body3Bold로 지음 

export const body3Bold = css`
  color: ${({ theme }) => theme.title.getColor()};
  font-size: 14px;
  font-weight: 600;
`;

export const body3Regular = css`
  color: ${({ theme }) => theme.title.getColor()};
  font-size: 14px; 
  font-weight: 400;
`;

export const body2Bold = css`
  color: ${({ theme }) => theme.title.getColor()};
  font-size: 16px;
  font-weight: 600;
`;