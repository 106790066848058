import styled from 'styled-components';
import { resultBtnStd } from '../styled';

export const SearchResultContent = styled.div`
  height: 100%;
  padding-top: 32px;
  border-right: 1px solid ${({ theme }) => theme.color.gray[300]};
  
  > h1 {
    margin-bottom: 16px;
    padding: 0 32px;
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font.body2};
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 16px 0 32px;
  
  > h1 {
    flex-grow: 1;
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font.body2};
  }
  
  > button {
    flex: none;
    margin-left: auto;
  }
`;

export const Content = styled.div`
  padding: 0 16px 40px;
`;

export const RecentBtn = styled.div`
  ${resultBtnStd};
  align-items: center;
  grid-template-columns: minmax(0, 1fr) 24px;
  grid-column-gap: 8px;
  padding: 8px 8px 8px 16px;
  cursor: pointer;
`;

export const Name = styled.p`
  color: ${({ theme }) => theme.color.gray[900]};
  ${({ theme }) => theme.font.body2};
  text-align: left;
`;

export const Address = styled.p`
  color: ${({ theme }) => theme.color.gray[600]};
  ${({ theme }) => theme.font.body3};
  text-align: left;
`;

export const RemoveBtn = styled.button`
  width: 24px;
  height: 24px;
  padding: 0;
  border: 0;
  background-color: transparent;
`;

export const TagWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 8px;
`;

export const NumTag = styled.p<{ $color: string; }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  color: ${({ $color }) => $color};
  ${({ theme }) => theme.font.caption2};
  font-weight: 500;
  white-space: nowrap;
  border: 1px solid ${({ $color }) => $color};
  border-radius: 20px;
`;

export const TextTag = styled.p<{ $color: string; }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 0 7px;
  color: ${({ $color }) => $color};
  ${({ theme }) => theme.font.caption2};
  font-weight: 400;
  border: 1px solid ${({ $color }) => $color};
  border-radius: 20px;
`;
