import styled from 'styled-components';

export const CloseBtn = styled.button`
  flex: none;
  width: 16px;
  height: 16px;
  margin-left: auto;
  border: 0;
  background-color: transparent;
  position: absolute;
  top: 28px;
  right: 30px;
`;

export const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 54px;

  > button {
    flex-grow: 1;
    width: 0;
    height: 100%;
    font-size: 15px;
    border: 0;
  }
`;

export const CancelBtn = styled.button`
  color: ${({ theme }) => theme.info.getColor()};
  background-color: ${({ theme }) => theme.bgGray.getColor()};

  &:hover,
  &:active {
    background-color: #ededed;
  }
`;

export const SubmitBtn = styled.button`
  color: ${({ theme }) => theme.blue.getColor()};
  font-weight: 700;
  background-color: ${({ theme }) => theme.bgBlue.getColor()};

  &:hover,
  &:active {
    background-color: #daeaff;
  }
`;
