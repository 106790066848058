import styled, { css } from 'styled-components';

export const SearchResultContent = styled.div`
  height: 100%;
  padding-top: 32px;
  border-right: 1px solid ${({ theme }) => theme.color.gray[300]};
  user-select: none;
  
  > h1 {
    margin-bottom: 16px;
    padding: 0 32px;
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font.body2};
  }
  
  &:nth-child(3) {
    border-right: 0;
  }
`;

export const resultBtnStd = css`
  display: grid;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 8px 16px;
  border: 0;
  border-radius: 12px;
  background-color: #fff;
  
  &:hover,
  &:focus-visible {
    background-color: ${({ theme }) => theme.color.gray[200]};
  }
`;

export const Name = styled.p`
  color: ${({ theme }) => theme.color.gray[900]};
  ${({ theme }) => theme.font.body2};
  text-align: left;

  > mark {
    color: ${({ theme }) => theme.color.blue[500]};
    background-color: transparent;
  }
`;

export const Address = styled.p`
  color: ${({ theme }) => theme.color.gray[600]};
  ${({ theme }) => theme.font.body3};
  text-align: left;
  
  > mark {
    color: ${({ theme }) => theme.color.blue[500]};
    background-color: transparent;
  }
`;
