import { useMemo } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import { useMapStore } from '~/store/map';
import { useMarkerStore } from '~/store/marker';
import { useSearchHistoryStore } from '~/store/search-history';

import useApiCall from '~/hooks/useApiCall';

import ga from '~/utils/ga';
import recommend from '~/utils/map/recommend';

interface Props {
  callbackAfterSend?: () => void;
}

interface SendArgs {
  search: Search.LocationStateSearch;
  center: Map.Location;
  zoom: number;
}

function useSearchSend({
  callbackAfterSend,
}: Props) {
  const location = useLocation();
  const history = useHistory<Search.LocationState>();

  // 지도페이지인 경우에만 존재한다.
  const { type } = useParams<{
    type: Map.TPathType;
  }>();

  const isMap = useMemo(() => {
    return location.pathname.indexOf('/map') === 0;
  }, [location.pathname]);

  const addHistory = useSearchHistoryStore(store => store.addHistory);

  const { put } = useApiCall();

  function feedBack(search: Search.SearchItem) {
    const params: {
      keyword: string;
      type: string;
      search_type: string;
      code?: number | string;
      id?: number | string;
      complex_id?: number | string;
      sale_in_lots_id?: number | string;
    } = {
      keyword: search.name,
      type: '',
      search_type: search.type === 'sale-in-lots' ? 'saleInLots' : 'room',
    };

    if (search.type === 'region') {
      params.code = search.id;
      params.type = search.type;

    } else if (search.type === 'subway' || search.type === 'univ') {
      params.id = search.id;
      params.type = search.type;

    } else if (search.type === 'officetel' || search.type === 'apt') {
      params.complex_id = search.id;
      params.type = 'complex';

    } else if (search.type === 'sale-in-lots') {
      params.sale_in_lots_id = search.id;
      params.type = 'sales';
    }

    return put('/api/3/loc/feedback', params);
  }

  function sendRoomMap({ search, center, zoom }: SendArgs, pathname?: string) {
    if (isMap) {
      let method: 'push' | 'replace' = 'replace';

      if (pathname) {
        if (location.pathname !== pathname) {
          method = 'push';
        }

      } else {
        let isIn = false;
        if (location.pathname.includes('drawing')) {
          const idx = location.pathname.indexOf('/drawing');
          pathname = location.pathname.slice(0, idx);
          method = 'replace';
          isIn = true;

        } else if (location.pathname.includes('distance')) {
          const idx = location.pathname.indexOf('/distance');
          pathname = location.pathname.slice(0, idx);
          method = 'replace';
          isIn = true;
        }

        if (isIn) {
          useMapStore.setState(draft => {
            draft.isMarkerReady = type;
            draft.isListReady = '';
          });

          useMarkerStore.setState({
            isHideMarker: false,
          });
        }
      }

      history[method]({
        pathname: pathname ? pathname : location.pathname,
        search: location.search,
        state: {
          needUpdate: true,
          search,
          center,
          zoom,
        }
      });

    } else {
      const lastType = localStorage.getItem('last-map-type');

      history.push({
        pathname: pathname ? pathname : lastType ? `/map/${lastType}` : '/map/onetwo',
        state: {
          needUpdate: true,
          search,
          center,
          zoom,
        }
      });
    }

    callbackAfterSend?.();
  }

  async function clickRegion(item: Search.SearchItem) {
    recommend.saveRegion({
      type: 'region',
      id: item.id as string,
    });

    addHistory(item);

    try {
      await feedBack(item);
    } catch(err) {
      console.log(err);
    }

    ga('검색', '검색', '지역');

    sendRoomMap({
      search: {
        type: 'region',
        id: item.id,
      },
      center: {
        lat: item.location[1],
        lng: item.location[0],
      },
      zoom: item.zoom,
    });
  }

  async function clickSubway(item: Search.SearchItem) {
    addHistory(item);

    try {
      await feedBack(item);
    } catch(err) {
      console.log(err);
    }

    ga('검색', '검색', '지하철');

    sendRoomMap({
      search: {
        type: 'subway',
        id: item.id,
      },
      center: {
        lat: item.location[1],
        lng: item.location[0],
      },
      zoom: item.zoom,
    });
  }

  async function clickUniv(item: Search.SearchItem) {
    addHistory(item);

    try {
      await feedBack(item);
    } catch(err) {
      console.log(err);
    }

    ga('검색', '검색', '대학교');

    sendRoomMap({
      search: {
        type: 'univ',
        id: item.id,
      },
      center: {
        lat: item.location[1],
        lng: item.location[0],
      },
      zoom: item.zoom,
    });
  }

  async function clickComplex(item: Search.SearchItem) {
    addHistory(item);

    try {
      await feedBack(item);
    } catch(err) {
      console.log(err);
    }

    ga('검색', '검색', '단지');

    sendRoomMap({
      search: {
        type: 'complex',
        id: item.id,
      },
      center: {
        lat: item.location[1],
        lng: item.location[0],
      },
      zoom: item.zoom,
    }, `/map/${item.type}`);
  }

  async function clickSaleInLots(item: Search.SearchItem) {
    addHistory(item);

    try {
      await feedBack(item);
    } catch(err) {
      console.log(err);
    }

    ga('검색', '검색', '분양');

    sendRoomMap({
      search: {
        type: 'sale-in-lots',
        id: item.id,
      },
      center: {
        lat: item.location[1],
        lng: item.location[0],
      },
      zoom: item.zoom,
    }, '/map/sale-in-lots');
  }

  return {
    clickRegion,
    clickSubway,
    clickUniv,
    clickComplex,
    clickSaleInLots,
  };
}

export default useSearchSend;
