import isObject from '~/utils/valid/is-object';

function replaceValue(value: any, newValue: any): any {
  if (Array.isArray(value) && Array.isArray(newValue)) {
    return newValue.map((val: any, i: number) => {
      return replaceValue(value[i], val);
    });

  } else if (isObject(value) && isObject(newValue)) {
    return mergeObject(value, newValue);
  }

  return newValue;
}

function mergeObject<T = any>(target: T, ...sources: Partial<T>[]): T {
  const temp = Object.assign({}, target);

  for (const source of sources) {
    for (const key in source) {
      if (source[key] === null || source[key] === undefined) continue;
      temp[key] = replaceValue(target[key], source[key]);
    }
  }

  return temp;
}

export default mergeObject;
