import { css } from 'styled-components';

import off from './images/check_off.svg';
import on from './images/check_on.svg';
import disabled from './images/check_disabled.svg';
import onWithDisabled from './images/check_on_disabled.svg';

export default css`
  width: 22px;
  height: 22px;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: #fff url(${off}) center center no-repeat;
  background-size: cover;
  appearance: none;
  cursor: pointer;

  &:checked {
    background-image: url(${on});
  }

  &:disabled {
    background-image: url(${disabled});
  }

  &:checked:disabled {
    background-image: url(${onWithDisabled});
  }
`;
