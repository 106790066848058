import { css } from 'styled-components';

const lg = css`
  height: 44px;
  
  > span {
    height: 24px;
    ${({ theme }) => theme.font.body3};
  }
`;

export default lg;