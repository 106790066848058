import styled from 'styled-components';
import SimpleBar from 'simplebar-react';
import 'simplebar/src/simplebar.css';

export const ScrollBar = styled(SimpleBar)`
  flex-grow: 1;
  height: 100%;

  .simplebar-content {
    height: 100%;
  }
`;
