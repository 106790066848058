import React, { CSSProperties } from 'react';

import useSearchSend from '~/hooks/search/useSearchSend';

import { MAX_SCROLL_HEIGHT } from '../config';
import Scrollbar from '~/components/scrollbar3';
import HighlightWords from '~/components/highlight-words';

import { SearchResultContent, Name, Address } from '../styled';
import { Content, SaleInLotsBtn, TextTag } from './styled';

interface Props {
  keywords: string[];
  saleInLots: Search.SearchItem[];
  clickClose(): void;
  style?: CSSProperties;
}

function SaleInLotsComponent({
  keywords,
  saleInLots,
  clickClose,
  style,
}: Props) {
  const {
    clickSaleInLots,
  } = useSearchSend({
    callbackAfterSend: clickClose,
  });

  if (saleInLots.length <= 0) return null;

  return (
    <SearchResultContent style={style}>
      <h1>분양</h1>

      <Scrollbar
        containerId="search-sale-in-lots-list"
        maxHeight={MAX_SCROLL_HEIGHT}
      >
        <Content>
          {saleInLots.map(s => {
            return (
              <SaleInLotsBtn
                key={s.id}
                onClick={clickSaleInLots.bind(null, s)}
              >
                <div>
                  <Name>
                    <HighlightWords
                      text={s.name}
                      searchWords={keywords}
                    />
                  </Name>
                  <Address>
                    <HighlightWords
                      text={s.address ?? ''}
                      searchWords={keywords}
                    />
                  </Address>
                </div>

                <div>
                  {s.child?.map((i, key) => {
                    return (
                      <TextTag key={key} $color={i.color}>
                        {i.name}
                      </TextTag>
                    );
                  })}
                </div>
              </SaleInLotsBtn>
            );
          })}
        </Content>
      </Scrollbar>
    </SearchResultContent>
  );
}

export default SaleInLotsComponent;
