import React from 'react';

import { useSearchHistoryStore } from '~/store/search-history';

import { ReactComponent as CloseSvg } from '../images/ic_24_close_gray700.svg';
import {
  RecentBtn, Name, Address,
  TagWrap, TextTag,
  RemoveBtn,
} from '../styled';

interface Props {
  item: Search.SearchItem;
  onClick: (item: Search.SearchItem) => Promise<void>;
}

function SaleInLotsRecentBtnComponent({
  item,
  onClick,
}: Props) {
  const removeSingleHistory = useSearchHistoryStore(store => store.removeSingleHistory);

  return (
    <RecentBtn>
      <div onClick={onClick.bind(null, item)}>
        <TagWrap>
          {item.child?.map((c, key) => {
            return (
              <TextTag
                key={key}
                $color={c.color}
              >
                {c.name}
              </TextTag>
            );
          })}
        </TagWrap>
        <Name>{item.name}</Name>
        <Address>{item.address}</Address>
      </div>

      <RemoveBtn onClick={removeSingleHistory.bind(null, item.id)}>
        <CloseSvg />
      </RemoveBtn>
    </RecentBtn>
  );
}

export default SaleInLotsRecentBtnComponent;
