import numComma from './num-comma';
import mergeObject from '~/utils/merge-object';

/**
 * 금액을 만,억,조,경 단위로 변경해준다.
 * 10000 -> 1억
 * 125000 -> 12억 5,000만
 *
 * 경단위가 넘어가면 문제가 생길수 있다.
 * 10000000000000000 (10000경) -> 1000경이 넘어가서 1 로만 표기되는 버그
 * 일반적으로 가격이 경이 넘어갈 일이 없기때문에 일단 무시한다.
 *
 * @param {String|Number} price - 금액
 * @param {String} unit - 최소 단위 [만|만원]
 * @return {string|number}
 */

interface Options {
  unit?: string;
  comma?: boolean;
  space?: boolean;
  useZero?: boolean;
}

const DEFAULT_OPTIONS: Options = {
  unit: '',
  comma: true,
  space: true,
  useZero: true,
};

function priceUnit(price: number = 0, options: Options = {}): string {
  options = mergeObject(DEFAULT_OPTIONS, options);

  const unitList = [options.unit, '억', '조', '경'];

  const piece = price.toString().replace(/\d(?=(?:\d{4})+(?:\.|$))/g, $0 => $0 + '_').split('_');
  const len = piece.length - 1;

  let result = '';
  let k = 0;
  for (let i = len; i >= 0; i--) {
    const n = piece[i];

    if (n === '') continue;

    if (n !== '0000') {
      const u = unitList[len - i] ?? '';
      const space = options.space ? k <= 0 ? '' : ' ' : '';

      if (options.comma) {
        result = (numComma(+n, options.useZero) + u + space) + result;
      } else {
        result = (+n + u + space) + result;
      }

      k++;
    }
  }
  return result;
}

export default priceUnit;
