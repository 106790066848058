import React, { MouseEvent, useState } from 'react';

import useAdInquiry from './useAdInquiry';

import Modal from 'web~/components/modal';

import Select from './select';

import { Wrap, Rows, Label, TermWrap, TermList } from './styled';

interface Props {
  handle: (e: MouseEvent) => void;
}

export default function AdInquiryModal({ handle }: Props) {
  const placeholder = '선택해주세요';
  const [open, setOpen] = useState(false);
  const {
    data: {
      ad_type,
      company_name,
      name,
      tel,
      email,
      ad_creative,
      ad_hope_date,
      accept,
    },
    onChangeField,
    onChangeTel,
    onChangeAccept,
    onFocusTel,
    onBlurTel,
    onSubmit,
  } = useAdInquiry(handle);

  return (
    <Modal
      handle={onSubmit}
      minWidth={400}
      type="confirm"
      cancelHandle={handle}
      submitName="제출하기"
      title={<h1>배너 광고 문의</h1>}
    >
      <>
        <form onSubmit={onSubmit}>
          <Wrap>
            <Rows>
              <p>광고유형</p>
              <Select
                value={ad_type}
                name="ad_type"
                placeholder={placeholder}
                onChange={onChangeField}
              >
                {['배너광고', '사업지광고', '기타'].map(p => (
                  <option key={p}>{p}</option>
                ))}
              </Select>
            </Rows>

            <Rows>
              <p>업체명</p>
              <input
                value={company_name}
                name="company_name"
                placeholder="(주) 다방건설"
                onChange={onChangeField}
              />
            </Rows>

            <Rows>
              <p>담당자명</p>
              <input
                value={name}
                name="name"
                placeholder="김다방"
                onChange={onChangeField}
              />
            </Rows>

            <Rows>
              <p>연락처</p>
              <input
                value={tel}
                name="tel"
                placeholder="- 없이 숫자만 입력"
                onChange={onChangeTel}
                onFocus={onFocusTel}
                onBlur={onBlurTel}
              />
            </Rows>

            <Rows>
              <p>이메일</p>
              <input
                value={email}
                name="email"
                placeholder="dabang@station3.co.kr"
                onChange={onChangeField}
              />
            </Rows>

            <Rows>
              <p>광고소재</p>
              <input
                value={ad_creative}
                name="ad_creative"
                placeholder="아파트, 가전 등"
                onChange={onChangeField}
              />
            </Rows>

            <Rows>
              <p>광고희망시기</p>
              <input
                value={ad_hope_date}
                name="ad_hope_date"
                placeholder="0000년 00월"
                onChange={onChangeField}
              />
            </Rows>

            <TermWrap>
              <Label>
                <input
                  type="checkbox"
                  checked={accept}
                  onChange={onChangeAccept}
                />
                <p>개인정보 수집·이용 동의 (필수)</p>
              </Label>

              <p onClick={() => setOpen(prev => !prev)}>내용보기</p>
            </TermWrap>
            {open && (
              <TermList>
                <li>
                  <p className="title">개인정보 수집 이용 목적</p>
                  <p className="content">광고 문의에 따른 상담 안내</p>
                </li>
                <li>
                  <p className="title">개인정보 수집 항목</p>
                  <p className="content">광고유형, 업체명, 담당자명, 연락처, 이메일, 광고소재, 광고희망시기</p>
                </li>
                <li>
                  <p className="title">개인정보의 보유 및 이용기간</p>
                  <p className="content">
                    <b><u>접수일로부터 90일간</u> 보관 후 파기</b>
                  </p>
                </li>
                <li>
                  <p className="desc">이용자는 개인정보 수집 및 이용을 거부할 권리가 있습니다.</p>
                  <p className="desc">다만 개인정보 수집 및 이용을 거부하실 경우 광고 문의 이용이 제한됩니다.</p>
                </li>
              </TermList>
            )}

            <p>담당자가 확인 후 빠른 시간 내에 회신 전화를 드립니다.</p>
          </Wrap>
        </form>
      </>
    </Modal>
  );
}
