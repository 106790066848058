import React, { forwardRef, useMemo, ChangeEvent } from 'react';

import type { TextareaProps } from './types';

import {
  TextareaLabel, Content,
  Border, LeftIconBtn, LeftIconWrap, TextArea,
  Unit,
} from './styled';

export const TextareaUIComponent = forwardRef<HTMLTextAreaElement, TextareaProps>(({
  contentWidth = '100%',
  contentHeight = '78px',
  leftIcon,
  onLeftIconClick,
  max,
  hideMax = false,
  maxLine,
  unit,
  isError = false,
  value ,
  onChange,
  onChangeNative,
  disabled = false,
  style,
  ...props
}, ref) => {
  const cnt = useMemo(() => {
    if (value !== undefined && max) {
      return typeof value === 'number' ? value.toString().length : value.length;
    }
    return 0;
  }, [max, value]);

  function change(e: ChangeEvent<HTMLTextAreaElement>) {
    const { name, value } = e.currentTarget;

    const lineCnt = value.split('\n').length;
    if (maxLine !== undefined && lineCnt > maxLine) return;

    onChangeNative?.(e);
    onChange?.({ name, value });
  }

  return (
    <TextareaLabel
      contentWidth={contentWidth}
      contentHeight={contentHeight}
      leftSpace={leftIcon ? 8 : 16}
      isError={isError}
      style={style}
    >
      <Content isDisabled={disabled}>
        {leftIcon && (
          onLeftIconClick ? (
            <LeftIconBtn
              onClick={onLeftIconClick}
              disabled={disabled}
            >
              {leftIcon}
            </LeftIconBtn>
          ) : (
            <LeftIconWrap>
              {leftIcon}
            </LeftIconWrap>
          )
        )}

        <TextArea
          ref={ref}
          isError={isError}
          hasIcon={!!leftIcon}
          hasUnit={!hideMax && (max !== undefined || unit !== undefined)}
          maxLength={max}
          value={value}
          onChange={change}
          disabled={disabled}
          autoComplete="off"
          {...props}
        />

        <Border />
      </Content>

      {max && !hideMax && value !== undefined && !disabled ? (
        <Unit isActive={cnt > 0}>
          {cnt}/{max}
        </Unit>
      ) : unit ? (
        <Unit isActive>{unit}</Unit>
      ) : null}
    </TextareaLabel>
  );
});

TextareaUIComponent.displayName = 'Textarea';
