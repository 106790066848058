import { BtnStdProps } from '.';

import btnSize from './size';

function getSize(size: GetIndexType<BtnStdProps, 'size'>) {
  switch (size) {
    case 'sm':
      return btnSize.sm;
    case 'md':
      return btnSize.md;
    case 'lg':
      return btnSize.lg;
    case 'xl':
      return btnSize.xl;
  }
}

export default getSize;
