import styled, { css } from 'styled-components';

export const InputWrap = styled.div<{
  contentWidth: string;
  isDisabled: boolean;
}>`
  display: block;
  width: ${({ contentWidth }) => contentWidth};
  height: 44px;
  position: relative;

  ${({ isDisabled }) => isDisabled && css`
    > button,
    > div {
      > svg {
        flex: none;
        
        circle[fill],
        ellipse[fill],
        line[fill],
        path[fill],
        polygon[fill],
        polyline[fill],
        rect[fill] {
          fill: ${({ theme }) => theme.color.gray[500]} !important;
        }
  
        circle[stroke],
        ellipse[stroke],
        line[stroke],
        path[stroke],
        polygon[stroke],
        polyline[stroke],
        rect[stroke] {
          stroke: ${({ theme }) => theme.color.gray[500]} !important;
        }
      }
    }
    
    > p.unit {
      color: ${({ theme }) => theme.color.gray[500]};
    }
  `}
`;

export const Input = styled.input<{
  leftSpace: number;
  rightSpace: number;
  isError: boolean;
}>`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.color.gray[900]};
  ${({ theme }) => theme.font.body3};
  font-weight: 400;
  padding: ${({ leftSpace, rightSpace }) => `0 ${rightSpace}px 0 ${leftSpace}px`};
  border: 1px solid ${({ isError, theme }) => isError ? theme.color.red[500] : theme.color.gray[300]};
  border-radius: 2px;
  background-color: ${({ theme }) => theme.color.white};
  transition: all 150ms ease-out;
  user-select: none;
  
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
  
  &:focus {
    border-color: ${({ isError, theme }) => isError ? theme.color.red[500] : theme.color.gray[900]};
  }

  &::placeholder {
    color: ${({ theme }) => theme.color.gray[600]};
  }
  
  &:disabled {
    color: ${({ theme }) => theme.color.gray[500]};
    border-color: ${({ theme }) => theme.color.gray[300]};
    background-color: ${({ theme }) => theme.color.gray[50]};
    
    &::placeholder {
      color: ${({ theme }) => theme.color.gray[500]};
      user-select: none;
    }
  }
`;

const icon = css`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const leftIcon = css`
  ${icon};
  left: 8px;
`;

export const LeftIconWrap = styled.div`
  ${leftIcon};
  pointer-events: none;
`;

export const LeftIconBtn = styled.button.attrs({
  type: 'button'
})`
  ${leftIcon};
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
`;

const rightIcon = css`
  ${icon};
  right: 8px;
`;

export const RightIconWrap = styled.div`
  ${rightIcon};
  pointer-events: none;
`;

export const RightIconBtn = styled.button.attrs({
  type: 'button'
})`
  ${rightIcon};
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
`;

export const Unit = styled.p.attrs({
  className: 'unit',
})<{
  isActive: boolean;
}>`
  flex: none;
  color: ${({ theme, isActive }) => isActive ? theme.color.gray[900] : theme.color.gray[600]};
  ${({ theme }) => theme.font.body3};
  font-weight: 400;
  white-space: nowrap;
  word-break: keep-all;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  pointer-events: none;
  user-select: none;
`;