import { css } from 'styled-components';

const md = css`
  height: 36px;

  > span {
    height: 24px;
    ${({ theme }) => theme.font.body3};
  }
`;

export default md;