import React from 'react';

import { TModal } from '~/@types/modal';
import { ReactComponent as CloseSvg } from '../images/close.svg';

import { CloseBtn } from './styled';

interface Props {
  handle: GetIndexType<TModal, 'handle'>;
  children: GetIndexType<TModal, 'children'>;
};

function CloseComponent({ handle, children }: Props) {
  return (
    <>
      <CloseBtn onClick={handle}>
        <CloseSvg />
      </CloseBtn>
      {children}
    </>
  );
}

export default CloseComponent;
