import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '~/store';
import { proUrl } from '~/enum/config';

import ProfileImage from '~/components/image-photo/profile';

import useProfile from './useProfile';

import { ReactComponent as LogoutSvg } from '../images/new-icon-logout.svg';
import {
  Wrap, Name, Arrow, Box,
  UserMenu, UserLink, AgentLink, Logout,
} from './styled';

function ProfileModule() {
  const {
    name,
    nickname,
    profileUrl,
    profileReset,
    isAgent,
  } = useSelector(({ user }: RootState) => user);

  const {
    _wrapper,
    isOpen,
    menuHandler,
    clickSms,
    clickCall,
    clickAccount,
    logout,
  } = useProfile();

  return (
    <Wrap ref={_wrapper}>
      <ProfileImage size={32} imgUrl={`${profileUrl}?_=${profileReset}`} />

      <Name onClick={menuHandler}>
        <p>{!isAgent ? nickname : name} 님</p>

        {isOpen && (
          <>
            <Arrow />
            <Box>
              <UserMenu>
                {!isAgent && (
                  <>
                    <UserLink
                      to="/user/sms"
                      onClick={clickSms}
                    >
                      문자문의
                    </UserLink>

                    <UserLink
                      to="/user/call"
                      onClick={clickCall}
                    >
                      전화문의
                    </UserLink>
                  </>
                )}

                <UserLink to="/user/inquiry">
                  1:1 문의내역
                </UserLink>

                <UserLink to="/user/report/list">
                  허위매물 신고내역
                </UserLink>

                {!isAgent ? (
                  <UserLink
                    to="/user/account"
                    onClick={clickAccount}
                  >
                    내 정보
                  </UserLink>
                ) : (
                  <AgentLink href={`${proUrl}/account/user`}>
                    내 정보
                  </AgentLink>
                )}

                <Logout onClick={logout}>
                  로그아웃
                  <LogoutSvg />
                </Logout>
              </UserMenu>
            </Box>
          </>
        )}
      </Name>
    </Wrap>
  );
}

export default ProfileModule;
