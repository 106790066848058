import styled from 'styled-components';

export const Header = styled.div`
  padding: 24px 32px;
  border-bottom: 1px solid ${({ theme  }) => theme.color.gray[200]};

  > h1 {
    ${({ theme }) => theme.font.body2};
    font-weight: 700;
  }
`;

export const Body = styled.div`
  padding: 32px 32px 40px;
`;

export const Notice = styled.div`
  margin-top: 16px;
  padding: 16px 0;
  background-color: ${({ theme }) => theme.color.blue[50]};
  text-align: center;

  span {
    margin-left: 4px;
    color: ${({ theme }) => theme.color.blue[500]};
  }
`;

export const BtnWrap = styled.div`
  margin-top: 32px;
  text-align: center;

  button {
    width: 180px;
  }

  button + button {
    margin-left: 8px;
  }
`;
