import { useState, useEffect, ChangeEvent, FormEvent, FocusEvent } from 'react';

import checkEmail from '~/utils/valid/check-email';
import checkTel from '~/utils/valid/check-tel';
import phoneFormat from '~/utils/format/phone-format';
import getErrorMessage from '~/utils/get-error-message';

import useApiCall from '~/hooks/useApiCall';
import { toast } from '~/components/toast';

export default function useAdInquiry(handle: Function) {
  const [data, setData] = useState({
    ad_type: '',
    company_name: '',
    name: '',
    tel: '',
    email: '',
    ad_creative: '',
    ad_hope_date: '',
    accept: false,
    isLoading: false
  });

  const { post } = useApiCall();

  useEffect(() => {
    if(data.isLoading) {
      callContact();
    }
  }, [data.isLoading]);

  function onChangeField(e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) {
    const { name, value } = e.currentTarget;
    setData(prevData => ({ ...prevData, [name]: value }));
  }

  function onChangeTel(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.currentTarget;

    if (!/^[0-9]*$/.test(value)) return;
    if (value.length > 11) return;

    setData(prevData => ({ ...prevData, tel: value }));
  }

  function onFocusTel(e: FocusEvent<HTMLInputElement>) {
    const { value } = e.currentTarget;
    setData(prevData => ({ ...prevData, tel: value.replace(/-/g, '') }));
  }

  function onBlurTel(e: FocusEvent<HTMLInputElement>) {
    const { value } = e.currentTarget;
    setData(prevData => ({ ...prevData, tel: phoneFormat(value) }));
  }

  function onChangeAccept(e: ChangeEvent<HTMLInputElement>) {
    const { checked } = e.currentTarget;
    setData(prevData => ({ ...prevData, accept: checked }));
  }

  function validate() {
    const {
      ad_type, ad_creative,
      company_name, name, tel, email, ad_hope_date,
      accept,
    } = data;

    if (ad_type === '') {
      toast.error('광고유형을 선택해 주세요');
      return false;
    }

    if (company_name === '') {
      toast.error('업체명을 작성해 주세요');
      return false;
    }

    if (name === '') {
      toast.error('담당자명을 작성해 주세요');
      return false;
    }

    if (tel === '') {
      toast.error('연락처를 작성해 주세요');
      return false;
    }

    if (checkTel(tel)) {
      toast.error('연락처를 정확히 입력해 주세요.');
      return false;
    }

    if (email === '') {
      toast.error('이메일을 작성해 주세요');
      return false;
    }

    if (checkEmail(email)) {
      toast.error('이메일 형식이 잘못되었습니다.');
      return false;
    }

    if (ad_creative === '') {
      toast.error('광고소재를 입력해 주세요.');
      return false;
    }

    if (ad_hope_date === '') {
      toast.error('광고희망시기를 작성해 주세요');
      return false;
    }

    if (!accept) {
      toast.error('약관에 동의해 주세요');
      return false;
    }
    return true;
  }

  async function callContact() {
    try {
      const {
        tel, accept, isLoading,
        ...params
      } = data;

      await post('/api/3/etc/ad/contact', {
        ...params,
        tel: tel.replace(/-/g, '')
      });

      toast.success('문의 신청이 완료되었습니다.');
      handle();

    } catch (err) {
      const msg = getErrorMessage(err).msg;
      toast.error(msg);
      setData(prevData => ({ ...prevData, isLoading: false }));
    }
  }

  function onSubmit(e: FormEvent) {
    e.preventDefault();
    if (data.isLoading) return;

    if (validate()) {
      setData(prevData => ({ ...prevData, isLoading: true }));
    }
  }

  return {
    data,
    onChangeField,
    onChangeTel,
    onChangeAccept,
    onFocusTel,
    onBlurTel,
    onSubmit
  };
}
