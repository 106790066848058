import { css } from 'styled-components';

import arrow from './images/select_arrow.svg';

export default css`
  height: 46px;
  padding: 0 8px 0 10px;
  color: ${({ theme }) => theme.form.getColor()};
  font-size: 14px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  box-sizing: border-box;
  background: url(${arrow}) right 8px center no-repeat;
  background-color: #fff;
  background-size: 10px 6px;
  outline: none;
  appearance: none;

  &::-ms-expand {
    // IE10 까지만 화살표 제거됨...
    display: none;
  }

  &::-ms-value {
    background: none;
    color: ${({ theme }) => theme.form.getColor()};
  }

  &:focus {
    border-color: ${({ theme }) => theme.blue.getColor()};
  }

  &:disabled {
    color: ${({ theme }) => theme.inactive.getColor()};
    border-color: ${({ theme }) => theme.line02.getColor()};
    background-color: ${({ theme }) => theme.bgGray.getColor()};
  }
`;
