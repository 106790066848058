import { useState } from 'react';

import useApiCall from '~/hooks/useApiCall';

import convertRegion from '~/utils/search/convert/region';
import convertSubway from '~/utils/search/convert/subway';
import convertUniv from '~/utils/search/convert/univ';
import convertComplex from '~/utils/search/convert/complex';
import convertSaleInLots from '~/utils/search/convert/sale-in-lots';
import getErrorMessage from '~/utils/get-error-message';

import { toast } from '~/components/toast';

const initSearch: Search.SearchState = {
  region: [],
  subway: [],
  univ: [],
  complex: [],
  saleInLots: [],
  roomId: '',
};

function useSearch() {
  const [searchedKeyword, setSearchedKeyword] = useState('');
  const [search, setSearch] = useState(initSearch);
  const [keywords, setKeywords] = useState<string[]>([]);

  const { get } = useApiCall();

  async function getSearch(keyword: string) {
    try {
      const sendKeyword = keyword;

      const res: Search.SearchResponse = await get(`/api/3/loc/search-keyword`, {
        keyword: sendKeyword,
        limit: 25,
      });

      let roomId = '';
      if (!isNaN(+keyword) && Number.isInteger(+keyword)) {
        roomId = await getRoomId(+keyword);
      }

      setSearch({
        region: convertRegion(res.region),
        subway: convertSubway(res.subway),
        univ: convertUniv(res.univ),
        complex: convertComplex(res.complex),
        saleInLots: convertSaleInLots(res.saleInLots),
        roomId,
      });

      setKeywords(res.keywords);
      setSearchedKeyword(sendKeyword);

    } catch (err) {
      const msg = getErrorMessage(err).msg;
      toast.error(msg);
    }
  }

  async function getRoomId(seq: number) {
    try {
      const { roomId } = await get(`/api/3/room/get-room-id`, {
        seq,
        type: 0,
      });

      return roomId ? roomId : '';

    } catch(err) {
      console.log(err);
      return '';
    }
  }

  function resetSearch() {
    setKeywords([]);
    setSearch(initSearch);
    setSearchedKeyword('');
  }

  return {
    searchedKeyword,
    search,
    keywords,
    getSearch,
    resetSearch,
  };
}

export default useSearch;
