import { css } from 'styled-components';

const xl = css`
  height: 56px;

  > span {
    height: 24px;
    ${({ theme }) => theme.font.body3};
  }
`;

export default xl;