import { css } from 'styled-components';

const line = css`
  &:disabled {
    border: 1px solid ${({ theme }) => theme.color.gray[400]};
    color: ${({ theme }) => theme.color.gray[500]};
    background-color: ${({ theme }) => theme.color.gray[100]};

    > svg {
      path {
        fill: ${({ theme }) => theme.color.gray[400]} !important;
      }
    }
  }
`;

const fill = css`
  &:disabled {
    border: 1px solid ${({ theme }) => theme.color.gray[400]};
    background-color: ${({ theme }) => theme.color.gray[400]};
  }
`;

const container = css`
  &:disabled {
    border: 1px solid ${({ theme }) => theme.color.gray[400]};
    color: ${({ theme }) => theme.color.gray[400]};
  }
`;

const disabledColor = {
  line,
  fill,
  container,
};

export default disabledColor;
