import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dispatch, RootState } from '~/store';

import getErrorMessage from '~/utils/get-error-message';

import useApiCall from '~/hooks/useApiCall';

import { toast } from '~/components/toast';

function useCheckCert() {
  const [isSending, setSending] = useState(false);
  const dispatch = useDispatch<Dispatch>();
  const user = useSelector(({ user }: RootState) => user);

  const { post } = useApiCall();

  function openCertPopup() {
    dispatch.user.update({ isCertRenewalPopup: 'cert' });
  }

  function closePopup() {
    dispatch.user.update({ isCertRenewalPopup: 'close' });
  }

  async function clickContinuePhone() {
    if (isSending) return;

    setSending(true);

    try {
      const res = await post('/api/3/user/confirm-phone-number');

      dispatch.user.update({
        ...res,
        isCertRenewalPopup: 'close',
      });

    } catch(err) {
      const msg = getErrorMessage(err).msg;
      toast.error(msg);
    } finally {
      setSending(false);
    }
  }

  async function editUserInfo({ name, phone }: { name: string; phone: string; }) {
    if (isSending) return;

    setSending(true);

    try {
      const res = await post('/api/3/user/safe-auth/edit', { name, phone });

      dispatch.user.update({
        ...res,
        isCertRenewalPopup: 'close',
      });
      toast.success('수정 되었습니다.');
    } catch(err) {
      const msg = getErrorMessage(err).msg;
      toast.error(msg);
    } finally {
      setSending(false);
    }
  }

  return {
    user,
    openCertPopup,
    clickContinuePhone,
    closePopup,
    editUserInfo,
  };
}

export default useCheckCert;
