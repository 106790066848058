import { useRef, useState, useEffect } from 'react';
import addSeconds from 'date-fns/addSeconds';
import differenceInSeconds from 'date-fns/differenceInSeconds';

function useTimer(second) {
  const _interval = useRef(null);
  const _cb = useRef(null);

  const [count, setCount] = useState(second);
  const [progress, setProgress] = useState(false);
  const [startTime, setStartTime] = useState(null);

  useEffect(() => { 
    if (startTime) {
      _interval.current = setInterval(() => {
        const end = addSeconds(startTime, second + 1);
        const diff = differenceInSeconds(end, new Date());

        if (diff <= 0) {
          stop(true);

        } else {
          setCount(diff);
        }
      }, 1000);
    }
  }, [second, startTime]);

  function start(callback) {
    stop();
    setProgress(() => true);
    setStartTime(new Date());
    _cb.current = callback ? callback : null;
  }

  function stop(isEnd = false) {
    setProgress(() => false);
    
    if (_interval.current) {
      setStartTime(null);
      setCount(0);
      clearInterval(_interval.current);
    }

    if (isEnd) {
      _cb.current && _cb.current(true);
    }
  }

  return [count, progress, start, stop];
}

export default useTimer;