import { css } from 'styled-components';

import disabledColor from '../color/disabled';

function fillHover() {

  return css`
    &:hover,
    &:active {
      background-color: ${({ theme }) => theme.color.gray[800]};
      border-color: ${({ theme }) => theme.color.gray[800]};
    }
  `;
}

const fill = css`
  background-color: ${({ theme }) => theme.color.gray[900]};
  border: 1px solid ${({ theme }) => theme.color.gray[900]};
  border-radius: 2px;
  color: ${({ theme }) => theme.color.white};

  > svg {
    flex: none;
    margin-right: 4px;

    path {
      fill: ${({ theme }) => theme.color.white} !important;
    }
  }

  ${fillHover()};
  ${disabledColor.fill};
`;

/**
 * line gray button style
 */
function lineHover() {

  return css`
    &:hover,
    &:active {
      background-color: ${({ theme }) => theme.color.gray[100]};
      border: 1px solid ${({ theme }) => theme.color.gray[400]};
    }
  `;
};

const line = css`
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.gray[400]};
  border-radius: 2px;
  color: ${({ theme }) => theme.color.gray[900]};

  > svg {
    flex: none;
    margin-right: 4px;

    path {
      fill: ${({ theme }) => theme.color.gray[900]} !important;
    }
  }

  ${lineHover()};
  ${disabledColor.line};
`;

/**
 * container gray button style
 */
function containerHover() {

  return css`
    &:hover {
      > span {
        border-bottom: 1px solid ${({ theme }) => theme.color.gray[900]};
      }
    }
  `;
};

const container = css`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.color.gray[900]};

  > svg {
    flex: none;
    margin-right: 4px;

    path {
      fill: ${({ theme }) => theme.color.gray[900]} !important;
    }
  }

  ${containerHover()};
  ${disabledColor.container};
 `;


const grayBtnColor = {
  lineHover, line,
  fillHover, fill,
  containerHover, container,
};

export default grayBtnColor;
