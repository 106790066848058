import React, { InputHTMLAttributes, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import checkboxStd from '~/components/common-styled/checkbox';

interface LabelProps {
  spaceTop: string;
  spaceBottom: string;
  boxVerticalAlign: 'center' | 'top' | 'bottom';
  isDisabled?: boolean;
}

const Label = styled.label<LabelProps>`
  display: inline-flex;
  margin-top: ${({ spaceTop }) => spaceTop};
  margin-bottom: ${({ spaceBottom }) => spaceBottom};
  cursor: ${({ isDisabled }) => isDisabled ? 'default' : 'pointer'};
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;

  ${({ boxVerticalAlign }) => boxVerticalAlign === 'top'
  ? css`align-items: flex-start;` 
  : boxVerticalAlign === 'bottom'
    ? css`align-items: flex-end;`
    : boxVerticalAlign === 'center'
      ? css`align-items: center;`
      : ``
  }
`;

const Checkbox = styled.input<{ boxSize: string; boxPosition: 'right' | 'left' }>`
  ${checkboxStd};
  width: ${({ boxSize }) => boxSize}px;
  height: ${({ boxSize }) => boxSize}px;
  
  ${({ boxPosition }) => boxPosition === 'right'
  ? css`margin-left: 4px;`
  : boxPosition === 'left'
    ? css`margin-right: 4px;`
    : ''
  }
`;

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  boxPosition: 'right' | 'left';
  boxSize: string;
  spaceTop: string;
  spaceBottom: string;
  boxVerticalAlign: 'center' | 'top' | 'bottom';
  children?: ReactNode | ReactNode[];
}

function LabelCheckboxComponent({
  boxVerticalAlign, boxPosition, boxSize, spaceTop, spaceBottom,
  children, disabled, ...props
}: Props) {
  return (
    <Label
      spaceTop={spaceTop}
      spaceBottom={spaceBottom}
      boxVerticalAlign={boxVerticalAlign}
      isDisabled={disabled}
    >
      {boxPosition === 'left' && (
        <Checkbox
          type="checkbox"
          boxSize={boxSize}
          boxPosition="left"
          disabled={disabled}
          {...props}
        />
      )}

      {children}

      {boxPosition === 'right' && (
        <Checkbox
          type="checkbox"
          boxSize={boxSize}
          boxPosition="right"
          disabled={disabled}
          {...props}
        />
      )}
    </Label>
  );
}

LabelCheckboxComponent.defaultProps = {
  boxPosition: 'left',
  boxVerticalAlign: 'center',
  boxSize: 24,
  spaceTop: '0',
  spaceBottom: '0',
};

export default LabelCheckboxComponent;
