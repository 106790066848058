import React from 'react';

import phoneFormat from '~/utils/format/phone-format';

import useCertCheck from './useCheckCert';

import Modal from 'web~/components/modal';
import CertPopup from 'web~/components/popup/cert';
import Button from '~/components/design-guide/button';

import { Header, Body, Notice, BtnWrap } from './styled';

interface Props {
  phone: string;
  clickContinuePhone: () => Promise<void>;
  openCertPopup: () => void;
}

export function CheckCertBody({ phone, clickContinuePhone, openCertPopup }: Props) {
  return (
    <>
      <Header>
        <h1>인증 후, 3개월이 지났어요!</h1>
      </Header>

      <Body>
        <p className="body3 gray-900 bold">아래 휴대폰 정보가 여전히 본인 번호가 맞으신가요?</p>
        <p className="body3 gray-700">변경되었다면 추가 인증을 다시 진행해주세요.</p>
        <p className="body3 gray-700">(번호를 갱신하지 않을 경우, 중개사로부터 연락을 받지 못할 수 있습니다.)</p>

        <Notice>
          <p className="body3 gray-900 bold">휴대폰 번호<span>{phoneFormat(phone)}</span></p>
        </Notice>

        <BtnWrap>
          <Button
            btnStyle="line"
            color="gray"
            size="xl"
            onClick={clickContinuePhone}
          >
            네, 맞아요
          </Button>

          <Button
            btnStyle="solid"
            color="blue"
            size="xl"
            onClick={openCertPopup}
          >
            아니요, 바꿀게요
          </Button>
        </BtnWrap>
      </Body>
    </>
  );
}

function CheckCertPopup() {
  const {
    user: { phone, isAuth, isCertRenewalPopup, isPhoneNumberCheck },
    clickContinuePhone, openCertPopup, closePopup, editUserInfo,
  } = useCertCheck();

  if (!isPhoneNumberCheck) return null;
  if (!isAuth) return null;
  if (isCertRenewalPopup === 'close') return null;

  return (
    isCertRenewalPopup === 'check' ? (
      <Modal
        type="modal"
        minWidth={520}
        handle={() => {}}
      >
        <CheckCertBody
          phone={phone}
          clickContinuePhone={clickContinuePhone}
          openCertPopup={openCertPopup}
        />
      </Modal>
    ) : isCertRenewalPopup === 'cert' ? (
      <CertPopup callback={editUserInfo} handler={closePopup} />
    ) : null
  );
}

export default CheckCertPopup;
