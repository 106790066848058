import { css } from 'styled-components';

import getSize from './getSize';
import getColor from './getColor';

export { default as btnColor } from './color';
export { default as btnSize } from './size';

export interface BtnStdProps {
  btnStyle: 'solid' | 'line' | 'container' | 'text';
  color: 'gray' | 'red' | 'blue';
  size: 'sm' | 'md' | 'lg' | 'xl';
  hasIcon?: boolean;
  boxPosition?: 'left' | 'right' | 'none';
}

export const baseStd = css<Pick<BtnStdProps, 'hasIcon' | 'boxPosition' | 'btnStyle'>>`
  flex: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${({ btnStyle }) => btnStyle === 'text' 
  ? css``
  : css`
    min-width: 80px;
    padding: 0 16px;
  `};
  font-weight: 700;
  vertical-align: middle;
  transition: all 150ms ease-out;
  cursor: pointer;

  > svg {
    flex: none;
    margin-right: ${({ boxPosition }) => boxPosition === 'left' && '4px'};
    margin-left: ${({ boxPosition }) => boxPosition === 'right' && '4px'};
  }

  > span {
    flex: none;
    display: block;
  }

  &:disabled {
    cursor: default;
  }
`;

export default css<BtnStdProps>`
  ${baseStd};
  ${({ btnStyle, color }) => getColor(btnStyle, color)};
  ${({ size }) => getSize(size)};
`;
