import React, { forwardRef, InputHTMLAttributes, ReactElement } from 'react';
import styled from 'styled-components';

import Input from '~/components/design-guide/input';

const InputWrap = styled.div`
  position: relative;

  > p.unit {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: default;
  }
`;

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  unit: ReactElement | ReactElement[] | null;
  showUnit?: boolean;
  width?: string;
  height?: string;
  showError?: boolean;
  errorText?: string;
}

const InputComponent = forwardRef<HTMLInputElement, Props>(({
  width, height,
  showUnit, unit,
  ...props
}, ref) => {
  return (
    <InputWrap>
      <Input
        ref={ref}
        width={width ?? '100%'}
        height={height ?? '44px'}
        {...props}
      />
      {(showUnit && unit) && unit}
    </InputWrap>
  );
});

InputComponent.defaultProps = {
  disabled: false,
  showUnit: true,
  showError: false,
  errorText: '',
};

export default InputComponent;
