import React, { CSSProperties } from 'react';

import useSearchSend from '~/hooks/search/useSearchSend';

import { MAX_SCROLL_HEIGHT } from '../config';
import Scrollbar from '~/components/scrollbar3';
import HighlightWords from '~/components/highlight-words';

import { SearchResultContent, Name } from '../styled';
import {
  Content, GroupContent,
  RegionBtn,
  SubwayBtn, NumTag, TextTag,
  UnivBtn,
} from './styled';

interface Props {
  keywords: string[];
  region: Search.SearchItem[];
  subway: Search.SearchItem[];
  univ: Search.SearchItem[];
  clickClose(): void;
  style?: CSSProperties;
}

function RegionSubwayUnivComponent({
  keywords,
  region, subway, univ,
  clickClose,
  style,
}: Props) {
  const {
    clickRegion,
    clickSubway,
    clickUniv,
  } = useSearchSend({
    callbackAfterSend: clickClose,
  });

  const size = region.length + subway.length + univ.length;

  if (size <= 0) return null;

  return (
    <SearchResultContent style={style}>
      <h1>지역, 지하철, 대학교</h1>

      <Scrollbar
        containerId="search-region-subway-univ-list"
        maxHeight={MAX_SCROLL_HEIGHT}
      >
        <Content>
          {region.length > 0 && (
            <GroupContent>
              {region.map(r => {
                return (
                  <RegionBtn
                    key={r.id}
                    onClick={clickRegion.bind(null, r)}
                  >
                    <Name>
                      <HighlightWords
                        text={r.fullName}
                        searchWords={keywords}
                      />
                    </Name>
                  </RegionBtn>
                );
              })}
            </GroupContent>
          )}

          {subway.length > 0 && (
            <GroupContent>
              {subway.map(s => {
                return (
                  <SubwayBtn
                    key={s.id}
                    onClick={clickSubway.bind(null, s)}
                  >
                    <Name>
                      <HighlightWords
                        text={s.name}
                        searchWords={keywords}
                      />
                    </Name>

                    <div>
                      {s.child?.map(c => {
                        if (!isNaN(+c.shortName) && Number.isInteger(+c.shortName)) {
                          return (
                            <NumTag key={c.name} $color={c.color}>
                              {c.shortName}
                            </NumTag>
                          );
                        }

                        return (
                          <TextTag key={c.name} $color={c.color}>
                            {c.shortName}
                          </TextTag>
                        );
                      })}
                    </div>
                  </SubwayBtn>
                );
              })}
            </GroupContent>
          )}

          {univ.length > 0 && (
            <GroupContent>
              {univ.map(u => {
                return (
                  <UnivBtn
                    key={u.id}
                    onClick={clickUniv.bind(null, u)}
                  >
                    <Name>
                      <HighlightWords
                        text={u.name}
                        searchWords={keywords}
                      />
                    </Name>
                  </UnivBtn>
                );
              })}
            </GroupContent>
          )}
        </Content>
      </Scrollbar>
    </SearchResultContent>
  );
}

export default RegionSubwayUnivComponent;
