import React from 'react';

import { useSearchHistoryStore } from '~/store/search-history';

import { ReactComponent as CloseSvg } from '../images/ic_24_close_gray700.svg';
import { RecentBtn, Name, RemoveBtn } from '../styled';

interface Props {
  item: Search.SearchItem;
  onClick: (item: Search.SearchItem) => Promise<void>;
}

function RegionRecentBtnComponent({
  item,
  onClick,
}: Props) {
  const removeSingleHistory = useSearchHistoryStore(store => store.removeSingleHistory);

  return (
    <RecentBtn>
      <div onClick={onClick.bind(null, item)}>
        <Name>{item.fullName}</Name>
      </div>

      <RemoveBtn onClick={removeSingleHistory.bind(null, item.id)}>
        <CloseSvg />
      </RemoveBtn>
    </RecentBtn>
  );
}

export default RegionRecentBtnComponent;
