import React, { ReactNode, ChangeEvent } from 'react';

import { Select } from './styled';

interface Props {
  name: string;
  value: string;
  placeholder: string;
  children?: ReactNode | ReactNode[];
  onChange:(e: ChangeEvent<HTMLSelectElement>) => void;
}

export default function SelectComponent({ name, value, placeholder, children, onChange }: Props) {
  return (
    <Select name={name} value={value} onChange={onChange}>
      {placeholder && <option value="">{placeholder}</option>}
      {children}
    </Select>
  );
};