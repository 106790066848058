import { css } from 'styled-components';

const sm = css`
  height: 28px;

  > span {
    height: 20px;
    ${({ theme }) => theme.font.caption1};
  }
`;

export default sm;