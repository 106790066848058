import React, { forwardRef, ReactElement } from 'react';

import { ScrollBar } from './styled';

interface Props {
  children: ReactElement | ReactElement[];
}

const  Scrollbar = forwardRef(({
  children,
}: Props, ref) => {
  return (
    <ScrollBar scrollableNodeProps={{ ref }}>
      {children}
    </ScrollBar>
  );
});

export default Scrollbar;
