import React, { forwardRef, SelectHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

import selectStd from '~/components/common-styled/select';

const Wrap = styled.div<{spaceTop?: string; spaceBottom?: string;}>`
  display: inline-flex;
  flex-direction: column;
  margin-top: ${({ spaceTop }) => spaceTop};
  margin-bottom: ${({ spaceBottom }) => spaceBottom};
`;

const Select = styled.select`
  ${selectStd};
  min-width: 160px;
`;

const Label = styled.p`
  width: 100%;
  margin-bottom: 8px;
  ${({ theme }) => theme.font.body3};
  color: ${({ theme }) => theme.color.gray[900]};
  font-weight: 700;
  user-select: none;

  > span {
    margin-left: 4px;
    color: ${({ theme }) => theme.color.blue[500]};
  }
`;

/**
 * @description select 박스 기본값은 noLabel enabled 상태입니다.
 * @value option 값을 선택했을 경우 color 짙게 설정 (type 변경 가능성 있음)
   * @spaceTop 아래 위 여백 설정
 */
interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  children: ReactNode;
  width?: string;
  height?: string;
  errorText?: string;
  value?: string;
  labelText?: string;
  isRequire?: boolean;
  spaceTop?: string;
  spaceBottom?: string;
}

const SelectComponent = forwardRef<HTMLSelectElement, Props>(({
  width, height,
  labelText, isRequire, errorText,
  spaceTop, spaceBottom, children, ...props
}, ref) => {
  return (
    <Wrap
      spaceTop={spaceTop}
      spaceBottom={spaceBottom}
    >
      {labelText && <Label>{labelText} {isRequire && <span>*</span>}</Label>}
      <Select
        ref={ref}
        contentWidth={width}
        contentHeight={height}
        {...props}
      >
        {children}
      </Select>
    </Wrap>
  );
});

SelectComponent.defaultProps = {
  height: '44px',
  labelText: '',
  isRequire: false,
  errorText: '',
  spaceTop: '0',
  spaceBottom: '0',
};

export default SelectComponent;
