import styled, { css } from 'styled-components';

export const Border = styled.div`
  border-radius: 2px;
  background-color: ${({ theme }) => theme.color.white};
  transition: all 150ms ease-out;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`;

export const TextareaLabel = styled.label<{
  contentWidth: string;
  contentHeight: string;
  leftSpace: number;
  isError: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: ${({ contentWidth }) => contentWidth};
  height: ${({ contentHeight }) => contentHeight};
  padding: ${({ leftSpace }) => `10px 16px 10px ${leftSpace}px`};
  position: relative;

  &:active ${Border} {
    border-color: ${({ isError, theme }) => isError ? theme.color.red[500] : theme.color.gray[900]};
  }
`;

export const Content = styled.div<{
  isDisabled: boolean;
}>`
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  ${({ isDisabled }) => isDisabled && css`
    > button,
    > div {
      > svg {
        flex: none;

        circle[fill],
        ellipse[fill],
        line[fill],
        path[fill],
        polygon[fill],
        polyline[fill],
        rect[fill] {
          fill: ${({ theme }) => theme.color.gray[500]} !important;
        }

        circle[stroke],
        ellipse[stroke],
        line[stroke],
        path[stroke],
        polygon[stroke],
        polyline[stroke],
        rect[stroke] {
          stroke: ${({ theme }) => theme.color.gray[500]} !important;
        }
      }
    }

    > p.unit {
      color: ${({ theme }) => theme.color.gray[500]};
    }
  `}
`;

export const TextArea = styled.textarea<{
  hasIcon: boolean;
  hasUnit: boolean;
  isError: boolean;
}>`
  padding: ${({ hasIcon }) => `10px 16px 10px ${hasIcon ? '44px' : '16px'}`};
  color: ${({ theme }) => theme.color.gray[900]};
  ${({ theme }) => theme.font.body3};
  font-weight: 400;
  border: 0;
  background-color: transparent;
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: ${({ hasUnit }) => hasUnit ? '34px' : '0'};
  user-select: none;
  resize: none;
  z-index: 1;

  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }

  & ~ ${Border} {
    border: 1px solid ${({ isError, theme }) => isError ? theme.color.red[500] : theme.color.gray[300]};
  }

  &:focus  ~ ${Border} {
    border-color: ${({ isError, theme }) => isError ? theme.color.red[500] : theme.color.gray[900]};
  }

  &::placeholder {
    color: ${({ theme }) => theme.color.gray[600]};
  }

  &:disabled {
    color: ${({ theme }) => theme.color.gray[500]};

    &::placeholder {
      color: ${({ theme }) => theme.color.gray[500]};
      user-select: none;
    }

    & ~ ${Border} {
      border-color: ${({ theme }) => theme.color.gray[300]};
      background-color: ${({ theme }) => theme.color.gray[50]};
    }
  }
`;

const leftIcon = css`
  flex: none;
  width: 24px;
  height: 24px;
  position: relative;
`;

export const LeftIconWrap = styled.div`
  ${leftIcon};
  pointer-events: none;
  user-select: none;
  z-index: 0;
`;

export const LeftIconBtn = styled.button.attrs({
  type: 'button'
})`
  ${leftIcon};
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  z-index: 2;

  &:disabled {
    cursor: default;
  }
`;

export const Unit = styled.p.attrs({
  className: 'unit',
})<{
  isActive: boolean;
}>`
  flex: none;
  color: ${({ theme, isActive }) => isActive ? theme.color.gray[900] : theme.color.gray[600]};
  ${({ theme }) => theme.font.body3};
  font-weight: 400;
  text-align: right;
  white-space: nowrap;
  word-break: keep-all;
  pointer-events: none;
  user-select: none;
`;
