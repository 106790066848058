import styled from 'styled-components';
import checkboxStd from '~/components/styled/checkbox';
import inputStd from '~/components/styled/input';
import {
  body1Form,
  body2Info,
  body3Contents,
  body3TitleB,
  body3Blue,
  body3Title,
  captionInfo,
} from '~/components/styled/text';

export const Wrap = styled.div`
  padding: 7px 30px 40px;

  > p {
    ${body3Blue};
    margin-top: 20px;
    line-height: 19px;
  }
`;

export const Rows = styled.div`
  display: flex;
  align-items: center;
  height: 52px;

  > p {
    ${body3TitleB};
    width: 100px;
  }

  > input {
    ${inputStd};
  }

  > select,
  input {
    width: 0;
    height: 42px;
    font-size: 14px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
  }
`;

export const TermWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  margin-top: 15px;
  border-top: 1px solid ${({ theme }) => theme.line02.getColor()};

  > p {
    ${body2Info};
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const TermList = styled.ul`
  margin-top: 20px;

  > li {
    margin-left: 6px;
    position: relative;

    > p {
      &.title {
        margin-bottom: 3px;
        color: ${({ theme }) => theme.color.gray[900]};
        ${({ theme }) => theme.font.caption1};
        font-weight: 400;

        &::before {
          content: '';
          width: 2px;
          height: 2px;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.color.gray[900]};
          position: absolute;
          top: 9px;
          left: -7px;
        }
      }

      &.content {
        color: ${({ theme }) => theme.color.gray[700]};
        ${({ theme }) => theme.font.caption1};
        font-weight: 400;
      }

      &.desc {
        color: #868686;
        ${({ theme }) => theme.font.caption1};
        font-weight: 400;

        &:first-child::before {
          content: '*';
          position: absolute;
          top: 0;
          left: -7px;
        }
      }
    }

    & + li {
      margin-top: 10px;
    }
  }
`;

export const Label = styled.label`
  display: flex;

  > p {
    ${body1Form};
    padding-left: 6px;
    line-height: 22px;
  }

  > input[type='checkbox'] {
    ${checkboxStd};
    width: 22px;
    height: 22px;
  }
`;
