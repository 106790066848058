import styled from 'styled-components';
import select from '~/components/styled/select';

export const Select = styled.select<{ value: string }>`
  ${select}
  width: 100%;
  height: 42px;
  color: ${({ value }) => value === "" ? 'rgb(187, 187, 187)' : 'rgb(34, 34, 34)'};

  & + select {
    margin-left: 10px;
  }
`;