import React, { forwardRef, InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import isEmpty from '~/utils/valid/is-empty';

import textInputStd from '~/components/common-styled/text-input';

const Input = styled.input`
  ${textInputStd};

  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
`;

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  width?: string;
  height?: string;
  showError?: boolean;
  errorText?: string;
}

const InputComponent = forwardRef<HTMLInputElement, Props>(({
  width, height,
  showError, errorText,
  ...props
}, ref) => {
  return (
    <>
      <Input
        ref={ref}
        contentWidth={width ?? '100%'}
        contentHeight={height ?? '44px'}
        autoComplete="off"
        isError={!isEmpty(errorText)}
        {...props}
      />
      {showError && errorText && <p className="error-text">{errorText}</p>}
    </>
  );
});

InputComponent.defaultProps = {
  showError: true,
  errorText: '',
};

export default InputComponent;
