import { css } from 'styled-components';

import disabledColor from './disabled';

/**
 * fill blue button style
 */
function fillHover() {
  return css`
    &:hover,
    &:active {
      background-color: ${({ theme }) => theme.color.blue[600]};
      border-color: ${({ theme }) => theme.color.blue[600]};
    }
  `;
}

const fill = css`
  background-color: ${({ theme }) => theme.color.blue[500]};
  border: 1px solid ${({ theme }) => theme.color.blue[500]};
  border-radius: 2px;
  color: ${({ theme }) => theme.color.white};

  > svg {
    flex: none;

    path {
      fill: ${({ theme }) => theme.color.white} !important;
    }
  }

  ${fillHover()};
  ${disabledColor.fill};
`;

/**
 * line blue button style
 */
function lineHover() {
  return css`
    &:hover,
    &:active {
      background-color: ${({ theme }) => theme.color.blue[50]};
      border: 1px solid ${({ theme }) => theme.color.blue[500]};
    }
  `;
};

const line = css`
  color: ${({ theme }) => theme.color.blue[500]};
  border: 1px solid ${({ theme }) => theme.color.blue[500]};
  border-radius: 2px;
  background-color: ${({ theme }) => theme.color.white};

  > svg {
    flex: none;

    path {
      fill: ${({ theme }) => theme.color.blue[500]} !important;
    }
  }

  ${lineHover()};
  ${disabledColor.line};
`;

/**
 * container blue button style
 */
function containerHover() {
  return css`
    &:hover,
    &:active {
      border-bottom: ${({ theme }) => theme.color.blue[500]};
    }
  `;
};

const container = css`
  background-color: transparent;
  color: ${({ theme }) => theme.color.blue[500]};
  border: none;

  > svg {
    flex: none;

    path {
      fill: ${({ theme }) => theme.color.blue[500]} !important;
    }
  }

  ${containerHover()};
  ${disabledColor.container};
 `;

const blueBtnColor = {
  lineHover, line,
  fillHover, fill,
  containerHover, container
};

export default blueBtnColor;
