import styled from 'styled-components';

import { TModal } from '~/@types/modal';
import { subtitle2TitleB } from '~/components/styled/text';

export const Content = styled.div`
  margin: 0 auto;
  background-color: #ffffff;
  box-shadow: 0 15px 18px -10px rgba(0, 0, 0, 0.25);
  transition: width 200ms ease-in-out;
  position: relative;
`;

type TOverlay = Pick<TModal, 'minWidth'>;

export const Overlay = styled.div<TOverlay>`
  width: 100%;
  min-width: ${({ minWidth }) => minWidth + 40}px;
  min-height: 100%;
  padding: 100px 20px;

  ${Content} {
    width: ${({ minWidth }) => minWidth}px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  border-bottom: 1px solid ${({ theme }) => theme.line02.getColor()};

  > h1 {
    flex-grow: 1;
    display: flex;
    align-items: center;
    height: 24px;
    ${({ theme }) => theme.font.body2};
    font-weight: 700;
  }
`;
