import React, { MouseEvent, ReactPortal } from 'react';
import { createPortal } from 'react-dom';

import { TModal } from '~/@types/modal';
import useModal from '~/hooks/useModal';
import ScrollBar from 'web~/components/scrollbar';

import Alert from './components/alert';
import Close from './components/close';
import Confirm from './components/confirm';

import { Overlay, Content, Header } from './styled';

/**
 * @param type - 타입에 따라 보여지는 모달이 달라진다.
 * - modal: 내용이 아무것도 없는 빈 모달 창
 * - close: 상단에 닫기 버튼만 있는 모달
 * - alert: 하단에 확인버튼 하나만 있는 모달
 * - confirm: 하단에 취소/확인 버튼이 있는 모달
 */
function ModalComponent({
  type, id, handle, cancelHandle,
  title, cancelName, submitName,
  minWidth, escToClose, children,
}: TModal): ReactPortal {
  const _elem = useModal({ type, id, handle, cancelHandle, escToClose });

  function block(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
  }

  return createPortal(
    <ScrollBar>
      <Overlay minWidth={minWidth} onClick={block}>
        <Content>
          {title && <Header>{title}</Header>}

          {type === 'modal' ? (
            children
          ) : type === 'alert' ? (
            <Alert handle={handle} submitName={submitName}>
              {children}
            </Alert>
          ) : type === 'confirm' ? (
            <Confirm
              handle={handle}
              cancelHandle={cancelHandle}
              cancelName={cancelName}
              submitName={submitName}
            >
              {children}
            </Confirm>
          ) : type === 'close' ? (
            <Close handle={handle}>
              {children}
            </Close>
          ) : children}
        </Content>
      </Overlay>
    </ScrollBar>,
    _elem.current,
  );
}

ModalComponent.defaultProps = {
  type: 'modal',
  id: 'modal',
  minWidth: 400,
  escToClose: true,
  cancelName: '취소',
  submitName: '확인',
};

export default ModalComponent;
