import styled from 'styled-components';

export const Wrap = styled.div<{ height: string; }>`
  display: flex;
  flex-direction: column;
  height: ${({ height }) => height};
  
  div.header {
    padding: 24px 32px;
    border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};

    h1 {
      ${({ theme }) => theme.font.body2};
    }
  }

  div.content {
    height: 565px;
    padding: 32px 0 0 32px;
  }

  div.error-content {
    padding: 32px 32px 40px;

    > p {
      ${({ theme }) => theme.font.body3};
      font-weight: 700;

      &.normal {
        white-space: pre-line;
        color: ${({ theme }) => theme.color.gray[900]};
      }

      &.email {
        margin-top: 32px;
        color: ${({ theme }) => theme.color.blue[500]};
        text-align: center;
      }
    }

    > div.btn-wrap {
      margin-top: 32px;
      text-align: center;

      > button {
        width: 114px;
      }
    }
  }

  div.footer {
    display: flex;
    align-items: center;;
    justify-content: center;
    height: 160px;
    margin-top: auto;

    > button {
      min-width: 114px;

      & + button {
        margin-left: 8px;
      }
    }
  }
`;

export const CertForm = styled.div`
  width: 100%;
  height: 100%;
  padding-right: 32px;

  > h2 {
    ${({ theme }) => theme.font.body3};
    font-weight: 700;
    margin-bottom: 48px;
  }
`;

export const JuminBox = styled.div`
  display: flex;
  align-items: center;

  > span {
    margin-left: 8px;
    margin-right: 8px;
    width: 10px;
    height: 1px;
    background-color: ${({ theme }) => theme.color.gray[400]};
  }

  > p {
    margin-left: 8px;
  }
`;

export const Label = styled.p`
  margin-bottom: 8px;
  font-weight: 700;
  ${({ theme }) => theme.font.body3};
`;

export const TermsWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding: 16px 0;
  border-top: 1px solid ${({ theme }) => theme.color.gray[200]};

  > div.term {
    display: flex;
    justify-content: space-between;
    width: 100%;

    & + div {
      margin-top: 16px;
    }
  }

  label {
    width: 100%;
  }

  svg {
    cursor: pointer;
  }
`;

export const TermsBox = styled.div`
  margin-top: 24px;
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  > div {
    display: flex;
    justify-content: space-between;

    > button {
      width: 114px;
    }
  }
`;
