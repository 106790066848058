import React, { useMemo } from 'react';

import useMapSearch from './useMapSearch';

import Result from 'web~/components/search-result';

import { ReactComponent as SearchSvg } from './images/ic_20_search_gray900.svg';
import { ReactComponent as RemoveSvg } from './images/ic_24_close_oval_gray.svg';
import {
  SearchContainer,
  Label, SearchIcon, Input, InputRightIconWrap, RemoveBtn,
  ResultContainer,
} from './styled';

function SearchComponent() {
  const {
    searchX,
    _search, _label, _input,
    isActive, isShowRemove,
    changeInput, focusInput,
    clickRemoveKeyword,
    clickClose,
    search, keywords, searchedKeyword,
    history,
  } = useMapSearch();

  const isShowResult = useMemo(() => {
    const isDetectedRoomId = !!search.roomId;
    const hasNormalResult = (search.region.length + search.subway.length + search.univ.length) > 0;
    const hasComplexResult = search.complex.length > 0;
    const hasSaleInLotsResult = search.saleInLots.length > 0;
    const hasRecent = searchedKeyword === '' &&  history.length > 0;
    const hasKeyword = !!searchedKeyword;
    return isDetectedRoomId || hasNormalResult || hasComplexResult || hasSaleInLotsResult || hasRecent || hasKeyword;
  }, [history, searchedKeyword, search]);

  return (
    <div ref={_search}>
      <SearchContainer>
        <Label ref={_label}>
          <SearchIcon style={{ left: searchX }}>
            <SearchSvg />
          </SearchIcon>

          <Input
            $isActive={isActive}
            ref={_input}
            id="search-input"
            onChange={changeInput}
            onFocus={focusInput}
            placeholder="지역, 지하철, 대학, 단지, 매물번호"
            autoComplete="off"
          />

          <InputRightIconWrap>
            {!isActive && <SearchSvg />}
            {isShowRemove && (
              <RemoveBtn onClick={clickRemoveKeyword}>
                <RemoveSvg />
              </RemoveBtn>
            )}
          </InputRightIconWrap>
        </Label>
      </SearchContainer>

      {isActive && (
        <ResultContainer $isShowResult={isShowResult}>
          <Result
            searchedKeyword={searchedKeyword}
            search={search}
            keywords={keywords}
            clickClose={clickClose}
          />
        </ResultContainer>
      )}
    </div>
  );
}

export default SearchComponent;
