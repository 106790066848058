import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from '~/store';

import useInitVh from '~/hooks/useInitVh';
import useCheck from './useCheck';

import Loading from '~/components/loading/page';

import Header from 'web~/components/header';
import Footer from 'web~/components/footer';
import CheckCert from 'web~/components/popup/check-cert';

import PrivateRoute from '~/containers/web/routes/private-route';
import PublicRoute from '~/containers/web/routes/public-route';

import GlobalStyle from './styled';

const Home = lazy(() => import(/* webpackChunkName: "home" */ '../views/home')); // 메인 홈

const DetailRoom = lazy(() => import(/* webpackChunkName: "detail-room" */ '../views/detail/room'));            // 매물 상세
const DetailComplex = lazy(() => import(/* webpackChunkName: "detail-complex" */ '../views/detail/complex'));   // 단지 상세
const DetailSales = lazy(() => import(/* webpackChunkName: "detail-sales" */ '../views/detail/sales'));         // 분양 상세

const AgentHome = lazy(() => import(/* webpackChunkName: "agent-home" */ '../views/agent-home'));               // 부동산 홈

const Map = lazy(() => import(/* webpackChunkName: "map" */ '../views/map'));                          // 지도

const Sales = lazy(() => import(/* webpackChunkName: "sales" */ '../views/sales'));                             // 분양 홈

const Favorite = lazy(() => import(/* webpackChunkName: "favorite" */ '../views/favorite'));                    // 관심목록 (최근 본 방, 찜한 방, 최근 본 단지, 찜한 단지, 즐겨찾기)
const Service = lazy(() => import(/* webpackChunkName: "service" */ '../views/service'));                       // 문의하기 (자주 묻는 질문, 1:1문의, 공지사항)
const MyDabang = lazy(() => import(/* webpackChunkName: "my-dabang" */ '../views/my-dabang'));                  // MY 다방 (문의항방, 연락한 부동산, 1:1문의내역, 허위매물 신고내역, 정보수정)
const Manage = lazy(() => import(/* webpackChunkName: "manage" */ '../views/manage'));                          // 방 내놓기 (방 내놓기, 내 방 관리)

const Welcome = lazy(() => import(/* webpackChunkName: "login" */ '../views/welcome'));                             // 로그인
const OAuth = lazy(() => import(/* webpackChunkName: "oauth" */ '../views/welcome/oauth'));                             // 로그인

const Recommend = lazy(() => import(/* webpackChunkName: "recommend" */ '../views/recommend'));                 // 이방어때

function Check() {
  useCheck();
  useInitVh();
  return null;
}

function Routes() {
  const { pathname } = useLocation();
  const isAgent = useSelector((store: RootState) => store.user.isAgent);

  return (
    <>
      <CheckCert />

      <div id="content">
        <GlobalStyle />
        <Check />

        <Header />

        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>

            <Route path="/:type(room|naver-room)/:id">
              <DetailRoom />
            </Route>
            <Route path="/complex/:id">
              <DetailComplex />
            </Route>
            <Route path="/sale-in-lots/:id">
              <DetailSales />
            </Route>

            <Route path="/agent/:id">
              <AgentHome />
            </Route>

            <Route path="/map/:type(onetwo|apt|house|officetel|sale-in-lots)?/:type2(distance|drawing)?/:seq?">
              <Map />
            </Route>

            <Route path="/sales">
              <Sales />
            </Route>

            <Route path="/favorite">
              <Favorite />
            </Route>
            <Route path="/service">
              <Service />
            </Route>

            <PrivateRoute path="/user">
              <MyDabang />
            </PrivateRoute>

            <PrivateRoute path="/manage/:id?">
              <Manage />
            </PrivateRoute>

            {!isAgent && (
              <PrivateRoute path="/manage/:id?">
                <Manage />
              </PrivateRoute>
            )}

            <Route path="/oauth/:type(kakao|apple)">
              <OAuth />
            </Route>

            <PublicRoute path="/welcome/:step(login|join|complete)?">
              <Welcome />
            </PublicRoute>

            <PrivateRoute path="/recommend">
              <Recommend />
            </PrivateRoute>

            <Redirect to="/" />
          </Switch>
        </Suspense>

        {pathname.indexOf('/map') < 0 && <Footer />}
      </div>
    </>
  );
}

export default Routes;
