import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';

import AdInquiryModal from 'web~/components/popup/sales-ad-inquiry';

import { Btn } from './styled';

function SalesAdInquiryPopup() {
  const [isOpen, setOpen] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const { salesAdInquiry } = qs.parse(location.search, { parseBooleans: true });

  function modalHandler() {
    setOpen(prev => !prev);
  }

  useEffect(() => {
    if (salesAdInquiry) {
      setOpen(true);
      history.replace('/');
    }
  }, []);

  return (
    <>
      <Btn onClick={modalHandler}>
        배너광고문의
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="7" cy="7" r="7" fill="#fff"/>
          <path d="M4.34378 9.34375L8.93997 4.74756C9.13524 4.55229 9.45182 4.55229 9.64708 4.74756C9.84234 4.94282 9.84234 5.2594 9.64708 5.45466L5.05089 10.0509L4.34378 9.34375Z" fill="#326CF9"/>
          <rect x="6.32843" y="4.67139" width="3" height="1" fill="#326CF9"/>
          <rect x="8.74252" y="8.08545" width="3" height="1" transform="rotate(-90 8.74252 8.08545)" fill="#326CF9"/>
        </svg>
      </Btn>
      {isOpen && (
        <AdInquiryModal handle={modalHandler} />
      )}
    </>
  );
}

export default SalesAdInquiryPopup;
