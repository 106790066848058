import { SelectHTMLAttributes } from 'react';
import { css } from 'styled-components';
import arrowDown from '~/components/svg-icon/images/ic-24-arrow-down-gray-500.svg';
import arrowUp from '~/components/svg-icon/images/ic-24-arrow-up-gray-900.svg';

interface selectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  contentWidth?: string;
  contentHeight?: string;
}

export default css<selectProps>`
  flex: none;
  width: ${({ contentWidth }) => contentWidth ?? '100%'};
  height: ${({ contentHeight }) => contentHeight ?? '44px'};
  padding: 10px 16px;
  color: ${({ value, theme }) => value ? `${theme.color.gray[900]}` : `${theme.color.gray[600]}`};
  font-size: 14px;
  line-height: 24px;
  border: 1px solid ${({ theme }) => theme.color.gray[300]};
  border-radius: 2px;
  background: url(${arrowDown}) right 8px center no-repeat;
  background-color: #fff;
  outline: none;
  appearance: none;

  &::-ms-expand {
    display: none;
  }

  &::-ms-value {
    background: none;
    color: ${({ theme }) => theme.color.gray[900]};
  }

  &:hover, &:active {
    border-color: ${({ theme }) => theme.color.gray[900]};
  }

  &:active {
    background: url(${arrowUp}) right 8px center no-repeat;
  }

  &:disabled {
    border-color: ${({ theme }) => theme.color.gray[300]};
    background-color: ${({ theme }) => theme.color.gray[50]};
  } 
`;
