import React, { forwardRef, useRef, useState, useMemo, useLayoutEffect } from 'react';

import type { BaseInputProps } from './types';

import {
  InputWrap,
  Input,
  LeftIconBtn, LeftIconWrap,
  RightIconBtn, RightIconWrap,
  Unit,
} from './styled';

export const BaseInputUIComponent = forwardRef<HTMLInputElement, BaseInputProps>(({
  contentWidth = '100%',
  leftIcon,
  onLeftIconClick,
  rightIcon,
  onRightIconClick,
  max,
  hideMax = false,
  unit,
  isError = false,
  value ,
  disabled = false,
  style,
  ...props
}, ref) => {
  const _unit = useRef<HTMLParagraphElement>(null);

  const [rightSpace, setRightSpace] = useState(16);

  const leftSpace = useMemo(() => leftIcon ? 40 : 16, [leftIcon]);

  const cnt = useMemo(() => {
    if (value !== undefined && max) {
      return typeof value === 'number' ? value.toString().length : value.length;
    }
    return 0;
  }, [max, value]);

  useLayoutEffect(() => {
    if (unit) {
      calcUnitSpace();

    } else if (rightIcon !== undefined) {
      setRightSpace(40);

    } else {
      setRightSpace(16);
    }
  }, [unit, rightIcon]);

  function calcUnitSpace() {
    if (_unit.current) {
      const rect = _unit.current.getBoundingClientRect();
      setRightSpace(rect.width + 24);

    } else {
      setTimeout(() => {
        calcUnitSpace();
      }, 60);
    }
  }

  return (
    <InputWrap
      contentWidth={contentWidth}
      isDisabled={disabled}
      style={style}
    >
      {leftIcon && (
        onLeftIconClick ? (
          <LeftIconBtn
            onClick={onLeftIconClick}
            disabled={disabled}
          >
            {leftIcon}
          </LeftIconBtn>
        ) : (
          <LeftIconWrap>
            {leftIcon}
          </LeftIconWrap>
        )
      )}

      <Input
        ref={ref}
        leftSpace={leftSpace}
        rightSpace={rightSpace}
        isError={isError}
        value={value}
        disabled={disabled}
        autoComplete="off"
        {...props}
      />

      {max && !hideMax && value !== undefined && !disabled ? (
        <Unit isActive={cnt > 0}>
          {cnt}/{max}
        </Unit>
      ) : unit ? (
        <Unit ref={_unit} isActive>
          {unit}
        </Unit>
      ) : rightIcon && (
        onRightIconClick ? (
          <RightIconBtn
            onClick={onRightIconClick}
            disabled={disabled}
          >
            {rightIcon}
          </RightIconBtn>
        ) : (
          <RightIconWrap>
            {rightIcon}
          </RightIconWrap>
        )
      )}
    </InputWrap>
  );
});

BaseInputUIComponent.displayName = 'BaseInput';
