import React, { CSSProperties } from 'react';

import { SearchResultContent, Content, Seq, LineLink } from './styled';

interface Props {
  roomSeq: string;
  roomId: string;
  style?: CSSProperties;
  clickClose(): void;
}

// 500209

function RoomSeqComponent({
  roomSeq,
  roomId,
  style,
  clickClose,
}: Props) {
  return (
    <SearchResultContent style={style}>
      <h1>일반매물</h1>

      <Content>
        <Seq>{roomSeq}</Seq>

        <LineLink
          btnColor="blue"
          btnSize="sm"
          href={`/room/${roomId}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={clickClose}
        >
          매물정보 보러가기
        </LineLink>
      </Content>
    </SearchResultContent>
  );
}

export default RoomSeqComponent;
