function shortNum(num: number): string {
  if (num < 1000) {
    return num.toString();
    
  } else if (num < 10000) {
    return parseFloat((num * 0.001).toFixed(1)) + '천';
    
  } else if (num >= 10000) {
    return parseFloat((num * 0.0001).toFixed(1)) + '억';
  }
  
  return num.toString();
}

export default shortNum;
